.events {
    .egroup-leader {
        top: 75px;
        transform: translate(0, 20px);

        .avatar {
            border: 5px solid #fff;
        }

        @media (max-width: 991px) {
            top: 0;
            transform: translate(10px, 50px);

            &.egroup-leader-expanded {
                transform: translate(0, 20px);
            }

            .avatar {
                top: 0;
            }

            .avatar2 {
                left: 65px;
            }
        }
    }

    .egroup-view-limited-details {
        margin-left: 40%;
        position: relative;

        .group-details {
            position: absolute;
            right: 0;
        }

        .group-details-btn {
            cursor: pointer;
        }
    }

    .egroup-leader-expanded {
        .avatar {
            top: 0;
        }
    }

    .egroup-leader-image-expanded {
        top: -90px;
    }

    .egroup-leader-image-not-expanded {
        top: -185px;
    }

    .join-button {
        background-color: $egroups-color;
        color: #fff;
    }

    .share-button {
        background-color: $gray-form-elements;
    }

    .egroup-info {
        align-items: center;
        padding-top: 40px;

        h1 {
            font-size: 24px;
            margin-bottom: 0;
        }

        p {
            font-size: 16px;
            opacity: 0.5;
        }
    }

    .egroup-leader-info {
        width: 60%;
    }

    @media (max-width: 991px) {
        .egroup-info {
            align-items: start;
            flex-direction: column;
            margin-bottom: 1.5em;
        }

        .egroup-leader-info {
            width: 100%;
        }

        .egroup-view-limited-details {
            margin-left: 0;
        }

        .egroup-leader-generic-image-not-expanded {
            top: 0;
        }

        .egroup-leader-image-not-expanded {
            top: -145px;
        }
    }

    .join-btn-text-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        .egroup-view-limited-details {
            margin-left: 0;
            padding-top: 55px;
        }

        .join-btn-text-mobile {
            display: block;
        }

        .join-btn-text,
        .group-details-text {
            display: none;
        }
    }

    @media (max-width: 500px) {
        .egroup-more-info {
            flex-direction: column;
        }

        // .egroup-view-limited-details .share-button,
        .join-btn-text,
        .group-details-text {
            display: none;
        }

        .join-btn-text-mobile {
            display: block;
        }
    }

    .egroup-more-info {
        .egroup-details {
            width: 50%;

            p {
                font-size: 16px;
            }

            p i {
                color: $dark-gray;
                font-size: 24px;
                opacity: 0.5;
            }
        }

        .egroup-desc {
            width: 50%;

            p {
                font-size: 16px;
            }

            .truncated-text,
            .original-text {
                color: $dark-gray;
                opacity: 0.5;
            }

            .more {
                display: none;
            }

            .more-button {
                cursor: pointer;
            }
        }

        @media (max-width: 500px) {
            .egroup-details,
            .egroup-desc {
                width: 100%;
            }
        }

        @media (max-width: 991px) {
            .egroup-details {
                margin-bottom: 2em;
            }
        }
    }

    @media (max-width: 500px) {
        .egroup-more-info {
            margin-top: 20px;
        }
    }
}
