.home,
.card-grid {
    .container-bigger {
        @media (min-width: 1200px) {
            max-width: 1300px;
        }
    }

    .first-time-img-div {
        border-radius: 8px 8px 0 0;
    }

    .static-size-card {
        height: 300px;
    }

    .egroup-card {
        &.smaller-card {
            @media (min-width: 1100px) {
                height: 300px;
            }

            @media (min-width: 1200px) {
                height: 270px;
            }
        }

        &.larger-card {
            @media (min-width: 1100px) {
                height: calc(600px + 4ex); //2 items height + margin bottom
            }

            @media (min-width: 1200px) {
                height: calc(540px + 4ex); //2 items height + margin bottom
            }
        }

        &:hover {
            color: $dark-gray;
        }
    }

    .card-image {
        flex-grow: 1;
        object-fit: cover;
    }

    .watch-parties-img-div,
    .need-prayer-img-div {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        div {
            img {
                object-position: top;
            }
        }
    }

    .question-egroup-img-div {
        background-color: rgba(24, 24, 24, 0.1);
    }

    .home-column {
        flex: 1;

        &.bigger {
            flex: 1.1;
        }
    }

    .home-grid {
        display: grid;
        grid-gap: 12px;

        grid-template-areas:
            "row1Col1"
            "row1Col2"
            "row3Col1"
            "row2Col2"
            "row3Col2"
            "row4Col2"
            "row1Col3"
            "row2Col3"
            "row3Col3";
        grid-template-columns: auto;

        @media (min-width: 991px) {
            grid-template-areas:
                "row1Col1 row1Col2 row1Col3 "
                "row1Col1 row2Col2 row2Col3 "
                "row3Col1 row3Col2 row3Col3 "
                ". row4Col2 . ";
        }

        .row1Col1 {
            grid-area: row1Col1;
        }

        .row1Col2 {
            grid-area: row1Col2;
        }

        .row1Col3 {
            grid-area: row1Col3;
        }

        .row2Col2 {
            grid-area: row2Col2;
        }

        .row2Col3 {
            grid-area: row2Col3;
        }

        .row3Col1 {
            grid-area: row3Col1;
        }

        .row3Col2 {
            grid-area: row3Col2;
        }

        .row3Col3 {
            grid-area: row3Col3;
        }

        .row4Col2 {
            grid-area: row4Col2;
        }
    }
}

@media screen and (max-width: 991px) {
    .mobile-full-width {
        margin-left: 0 !important;
        margin-right: 0 !important;
        min-width: 100% !important;
    }
}

.cards-wrapper {
    position: relative;

    .icon-div {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        min-height: 50px;
        min-width: 50px;
    }

    .icon-egroup {
        background-color: $egroupsBlue;
    }

    .icon-eteam,
    .icon-watchparty,
    .icon-generic {
        background-color: #ff671f;
    }

    .icon-baptism,
    .icon-salvation {
        background-color: #1da1f2;
    }

    .egroup-icon {
        width: 32px;
    }

    .card-image {
        border-radius: 16px 16px 0 0;
        flex-grow: 1;
    }

    .card-smallPadding {
        padding: 0.7em 0 0.7em 1em;
    }

    .egroup-card {
        &.smaller-card {
            @media (min-width: 991px) {
                height: 300px;
            }

            @media (min-width: 1200px) {
                height: 270px;
            }
        }

        &.larger-card {
            @media (min-width: 991px) {
                height: calc(600px + 4ex); //2 items height + margin bottom
            }

            @media (min-width: 1200px) {
                height: calc(540px + 4ex); //2 items height + margin bottom
            }
        }

        &:hover {
            color: #18181c;
        }
    }
}

.card-img-div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    div {
        img {
            object-position: center;
        }
    }
}
