/** Import mixins */
@use "sass:math";
@import "mixins/global";
@import "mixins/spacers";
@import "mixins/borders";
@import "mixins/breakpoints";

$vendors: ("-webkit-", "-moz-", "-ms-", "-o-", "");

// Define default font size
@function calculaterem($size) {
    $rem-size: math.div($size, 16px);

    @return $rem-size * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculaterem($size);
}

// Columns mixin setup
@mixin content-columns($columnCount, $columnGap: $gridGutterWidth) {
    column-count: $columnCount;
    column-gap: $columnGap;
}

//Animation mixin setup
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

// Transform mixin setup
@mixin transform($args) {
    @each $vendor in $vendors {
        #{$vendor}transform: $args;
    }
}

// Transition mixin setup
@mixin transition($args...) {
    @each $vendor in $vendors {
        #{$vendor}transition: $args;
    }
}

@mixin animation($str) {
    @each $vendor in $vendors {
        #{$vendor}animation: #{$str};
    }
}

$widths: 60;
$width-set: 500;
$vw-set: 100;
$heights: 60;
$height-set: 500;
$vh-set: 100;
$font-sizes: 150;

@mixin w-list {
    @for $i from 1 through $widths {
        .max-w-#{$i * 25} {
            max-width: #{$i * 25}px !important;
        }

        .min-w-#{$i * 25} {
            min-width: #{$i * 25}px !important;
        }
    }
}

@mixin mob-w-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $vw-set {
            .mob-max-w-#{$i * 25} {
                max-width: #{$i * 25}px !important;
            }

            .mob-min-w-#{$i * 25} {
                min-width: #{$i * 25}px !important;
            }
        }
    }
}

@mixin vw-list {
    @for $i from 1 through $vw-set {
        .max-vw-#{$i * 1} {
            max-width: #{$i * 1}vw !important;
        }

        .min-vw-#{$i * 1} {
            min-width: #{$i * 1}vw !important;
        }
    }
}

@mixin mob-vw-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $vw-set {
            .mob-max-vw-#{$i * 1} {
                max-width: #{$i * 1}vw !important;
            }

            .mob-min-vw-#{$i * 1} {
                min-width: #{$i * 1}vw !important;
            }
        }
    }
}

@mixin w-set-list {
    @for $i from 1 through $width-set {
        .w-#{$i * 4} {
            width: #{$i * 4}px;
        }
    }
}

@mixin mob-w-set-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $width-set {
            .mob-w-#{$i * 4} {
                width: #{$i * 4}px;
            }
        }
    }
}

@mixin ht-list {
    @for $i from 1 through $heights {
        .max-ht-#{$i * 25} {
            max-height: #{$i * 25}px;
        }

        .min-ht-#{$i * 25} {
            min-height: #{$i * 25}px;
        }
    }
}

@mixin mob-ht-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $heights {
            .mob-max-ht-#{$i * 25} {
                max-height: #{$i * 25}px !important;
            }

            .mob-min-ht-#{$i * 25} {
                min-height: #{$i * 25}px !important;
            }
        }
    }
}

@mixin vh-list {
    @for $i from 1 through $vh-set {
        .max-vh-#{$i * 1} {
            max-height: #{$i * 1}vh;
        }

        .min-vh-#{$i * 1} {
            min-height: #{$i * 1}vh;
        }
    }
}

@mixin mob-vh-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $vh-set {
            .mob-max-vh-#{$i * 1} {
                max-height: #{$i * 1}vh !important;
            }

            .mob-min-vh-#{$i * 1} {
                min-height: #{$i * 1}vh !important;
            }
        }
    }
}

@mixin ht-set-list {
    @for $i from 1 through $height-set {
        .ht-#{$i * 4} {
            height: #{$i * 4}px;
        }
    }
}

@mixin mob-ht-set-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $height-set {
            .mob-ht-#{$i * 4} {
                height: #{$i * 4}px;
            }
        }
    }
}

@mixin font-size-list {
    @for $i from 1 through $font-sizes {
        .font-size-#{$i * 2} {
            font-size: #{$i * 2}px;
        }
    }
}

@mixin mob-font-size-list {
    @media screen and (max-width: 991px) {
        @for $i from 1 through $font-sizes {
            .mob-font-size-#{$i * 2} {
                font-size: #{$i * 2}px !important;
            }
        }
    }
}

@include w-list;
@include mob-w-list;
@include w-set-list;
@include mob-w-set-list;
@include ht-list;
@include mob-ht-list;
@include ht-set-list;
@include mob-ht-set-list;
@include font-size-list;
@include mob-font-size-list;
@include vw-list;
@include mob-vw-list;
@include vh-list;
@include mob-vh-list;
