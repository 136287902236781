.filter-box {
    border-radius: 8pt;

    &#map-filter {
        left: 1em;
        top: 6.3em;
        width: 320px;
    }

    .filter-box-option-div,
    .filter-box-header {
        border-bottom: 1px solid #d3d3d3;
    }

    .filter-box-option-div:last-child {
        border-bottom: 0;
    }

    .filter-box-option-div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1em;
    }

    .filter-box-header {
        display: flex;
        justify-content: space-between;
    }

    .filter-box-header > span {
        font-family: $secondary-font-family;
        font-weight: bold;
    }

    .iconWrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 32px;
        justify-content: center;
        margin-right: 0.5em;
        width: 32px;
    }

    .iconWrapper-watchParties {
        background-color: #ff671f;
    }

    .iconWrapper-efam {
        background-color: #b4d6ec;
    }

    .iconWrapper-egroup {
        background-color: #c3d25a;
    }

    .iconLabel-wrapper {
        align-items: center;
        display: flex;
    }

    .fa-home,
    .fa-user,
    .ecicon-egroups-active {
        color: #fff;
    }

    .fa-sliders-v {
        font-size: 24px !important;
    }

    .noButtonStyle {
        background-color: transparent;
        border: 0;
        float: right;
        outline: none !important;
    }

    .noBorder {
        border: 0 !important;
    }

    .filterLoading {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .filterSubTitle {
        color: $dark-gray;
        font-family: $secondary-font-family;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        margin-bottom: 5px;
    }

    .selectWrapper {
        float: left;
        position: relative;
    }

    .selectWrapper::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        font-weight: 100;
        height: 34px;
        pointer-events: none;
        position: absolute;
        right: 1em;
        top: 50%;
    }

    select {
        appearance: none;
        /* Add some styling */

        background-color: hsla(0, 0%, 9%, 0.05);
        background-image: none;
        border: 1px solid #fff;
        color: #333;
        display: block;
        float: right;
        font-size: 16px;
        height: 50px;
        line-height: 1.75;
        width: 100%;
        -ms-word-break: normal;
        word-break: normal;
    }

    .filterCollapsed::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        font-weight: 100;
    }

    .filterOpen::after {
        color: $dark-gray;
        content: "\f077";
        font-family: $icons-font-family;
        font-weight: 100;
    }

    .collapsableOptions {
        max-height: 0;
        overflow-y: auto;
        transition: max-height 150ms, opacity 250ms, flex 250ms;
        transition-delay: 200ms;
    }

    .collapsableOptions::-webkit-scrollbar {
        background: transparent; /* Optional: just make scrollbar invisible */
        width: 0; /* Remove scrollbar space */
    }

    .collapsableOptions.filterOpened {
        max-height: 200px;
    }

    .egroupMenu {
        max-height: 0;
        overflow-y: auto;
        transition: max-height 150ms, opacity 150ms;

        transition-delay: 200ms;
    }

    .egroupMenu::-webkit-scrollbar {
        background: transparent; /* Optional: just make scrollbar invisible */
        width: 0; /* Remove scrollbar space */
    }

    .egroupMenu.showEgroups {
        max-height: 675px;
    }

    .egroupExpanded {
        overflow-y: auto;
        transition: max-height 150ms, opacity 250ms;
    }

    .collapsableOptions.filterOpened.egroupExpanded {
        max-height: 67vh;
    }
}

@media only screen and (min-width: 552px) {
    #groups-list-filter {
        .filter-box-header {
            display: none;
        }
    }
}

@media only screen and (max-width: 551px) {
    #groups-list-filter {
        margin-bottom: 1.5em;
    }

    .filter-box {
        position: relative;

        &#map-filter {
            border-radius: 24px 24px 0 0;
            bottom: 0;
            left: 0;
            margin: 0;
            position: fixed;
            right: 0;
            top: initial;
            width: 100vw;
        }
    }

    .filterCollapsed::after {
        content: "\f077" !important;
    }

    .filterOpen::after {
        content: "\f078" !important;
    }

    .filterCollapsed::after {
        content: "\f077" !important;
    }

    .filterOpen::after {
        content: "\f078" !important;
    }
}

@media only screen and (max-width: 991px) {
    .map-searchbox {
        left: 1em !important;
        right: 1em;
    }

    .map-searchbox > input {
        max-width: 100% !important;
        width: 100% !important;
    }
}

.campus-checkbox {
    border-radius: 20px;
    margin-right: 4px;
}
