$cap: 1px; // Cap-height of the $b0 font size

$border-none: 0; // 0
$border-2xs: ceil($cap * 0.5); // .5px
$border-xs: $cap; // 1px
$border-sm: round($cap * 1.5); // 1.5px
$border-md: round($cap * 3); // 3px
$border-lg: round($cap * 4); // 4px
$border-xl: round($cap * 5); // 5px
$border-2xl: round($cap * 10); // 10px
$border-3xl: round($cap * 20);

$sizes: (
    "none": $border-none,
    "2xs": $border-2xs,
    "xs": $border-xs,
    "sm": $border-sm,
    "md": $border-md,
    "lg": $border-lg,
    "xl": $border-xl,
    "2xl": $border-2xl,
    "3xl": $border-3xl,
);

// margin
@each $size, $value in $sizes {
    .b-width-#{$size} {
        border: $value;
        border-style: solid;
    }

    .b-t-width-#{$size} {
        border-style: solid;
        border-top: $value;
    }

    .b-b-width-#{$size} {
        border-bottom: $value;
        border-style: solid;
    }

    .b-l-width-#{$size} {
        border-left: $value;
        border-style: solid;
    }

    .b-r-width-#{$size} {
        border-right: $value;
        border-style: solid;
    }

    .b-x-width-#{$size} {
        border-left: $value;
        border-right: $value;
        border-style: solid;
    }

    .b-y-width-#{$size} {
        border-bottom: $value;
        border-style: solid;
        border-top: $value;
    }
}

@each $size, $value in $sizes {
    .mob-b-width-#{$size} {
        @media (max-width: 991px) {
            border: $value;
            border-style: solid;
        }
    }

    .mob-b-t-width-#{$size} {
        @media (max-width: 991px) {
            border-style: solid;
            border-top: $value;
        }
    }

    .mob-b-b-width-#{$size} {
        @media (max-width: 991px) {
            border-bottom: $value;
            border-style: solid;
        }
    }

    .mob-b-l-width-#{$size} {
        @media (max-width: 991px) {
            border-left: $value;
            border-style: solid;
        }
    }

    .mob-b-r-width-#{$size} {
        @media (max-width: 991px) {
            border-right: $value;
            border-style: solid;
        }
    }

    .mob-b-x-width-#{$size} {
        @media (max-width: 991px) {
            border-left: $value;
            border-right: $value;
            border-style: solid;
        }
    }

    .mob-b-y-width-#{$size} {
        @media (max-width: 991px) {
            border-bottom: $value;
            border-style: solid;
            border-top: $value;
        }
    }
}

// Mixins for the borders.
// usage: @include b-t-width-($border-md);
@mixin b-width-($value) {
    border: $value;
    border-style: solid;
}

@mixin b-width-($value) {
    border: $value;
    border-style: solid;
}

@mixin b-t-width-($value) {
    border-style: solid;
    border-top: $value;
}

@mixin b-b-width-($value) {
    border-bottom: $value;
    border-style: solid;
}

@mixin b-l-width-($value) {
    border-left: $value;
    border-style: solid;
}

@mixin b-r-width-($value) {
    border-right: $value;
    border-style: solid;
}

@mixin b-x-width-($value) {
    border-left: $value;
    border-right: $value;
    border-style: solid;
}

@mixin b-y-width-($value) {
    border-bottom: $value;
    border-style: solid;
    border-top: $value;
}
