form.phone-number-form {
    .react-tel-input {
        border: 0 !important;
        box-shadow: none !important;
        height: 56px;
        width: 100%;

        input {
            border: 1px solid $translucentLightGreyAlt;
            border-radius: 8px;
            box-shadow: none;
            font-family: $primary-font-family;
            font-size: 16px;
            height: 56px;
            padding-left: 4em;
            width: 100% !important;

            &.invalid-number {
                border: 1px solid rgba(60, 60, 67, 0.3);
            }
        }

        .flag-dropdown {
            background: transparent !important;
            border: 0;
            border-right: 1px solid $translucentLightGreyAlt;
            padding-right: 16px;
        }

        .selected-flag,
        .selected-flag:hover,
        .selected-flag.open {
            background: transparent;
            border-radius: 8px 0 0 8px;
            height: 100%;
            outline: none;
            padding-left: 17px;
            padding-right: 16px;
            text-align: center;
        }
    }
}

form.digits-form {
    .digits-input {
        border: 2px solid rgba(60, 60, 67, 0.18);
        border-radius: 8px;
        height: 70px;
        max-width: 55px;
        outline: 0;
        padding-bottom: 1em;

        &:focus {
            border: 2px solid $focusBlue;
        }
    }

    .field {
        margin-left: 6px;
        margin-right: 6px;
    }

    @media only screen and (max-width: 400px) {
        // .digits-input {
        //     height: 45px;
        // }

        .field {
            margin-left: 1px;
            margin-right: 1px;
        }
    }
}

.react-switch-checkbox {
    height: 0;
    visibility: hidden;
    width: 0;
}

.react-switch-label {
    align-items: center;
    background: rgb(128, 128, 128);
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: space-between;
    position: relative;
    transition: background-color 0.2s;
    width: 60px;
}

.react-switch-label .react-switch-button {
    background: #fff;
    border-radius: 21px;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    content: "";
    height: 21px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: 0.2s;
    width: 21px;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

.progress-bar-wrapper {
    background: $offWhite;
    height: 7px;
    .progress-bar {
        background: $ecOrange;
    }
}

.label-animation-input {
    input:focus,
    input:focus-visible {
        border-color: $focusBlue !important;
        border-width: 2px !important;
        outline: 0;
    }

    input::placeholder {
        opacity: 0;
    }

    input {
        border: 1px solid rgba(60, 60, 67, 0.3);
    }

    label {
        color: $translucentLightGreyAlt;
        font-size: 16px;
        left: 16px;
        line-height: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label,
    &.filled label {
        color: #000;
        top: 0%;
        transform: translate(0, -100%) scale(0.8);
    }

    &.filled input {
        border: 1px solid $translucentLightGreyAlt;
    }
    &:focus-within input::placeholder {
        opacity: 1;
    }

    &.with-phone-field {
        label {
            left: 4em;
        }

        &:focus-within label,
        &.filled label {
            left: 16px;
        }
    }
}

.form {
    .phone-number-field {
        border-radius: 10px !important;
        height: 38px !important;
        width: 100% !important;
    }

    .react-tel-input {
        .flag-dropdown {
            background-color: #fff !important;
            border-radius: 10px 0 0 10px;
        }
        .selected-flag:hover {
            background-color: transparent !important;
        }
        .flag-dropdown.open {
            border-radius: 10px 0 0 !important;
        }
    }

    .ui.selection.dropdown > .delete.icon,
    .ui.selection.dropdown > .dropdown.icon,
    .ui.selection.dropdown > .search.icon {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .filled {
        .ui {
            &.selection {
                &.dropdown {
                    border-color: $translucentLightGreyAlt;
                }
            }
        }
    }
    .ui {
        &.selection {
            &.dropdown {
                border-color: rgba(60, 60, 67, 0.3);
            }

            &:focus,
            &.active.dropdown,
            &.active.dropdown .menu {
                border-color: $focusBlue !important;
                border-width: 2px !important;
            }

            &.active.dropdown .menu {
                border-top-width: 0 !important;
                box-shadow: none;
                left: -2px;
                margin: 0;
                outline: 0;
                right: -2px;
                width: unset;
            }

            &.active.dropdown.upward .menu {
                border-bottom-width: 0 !important;
                border-top-width: 2px !important;
            }
        }
        &.dropdown {
            border-radius: 8px;
            line-height: unset !important;
            min-height: 58px;
            padding: 1em 3em !important;
            padding-left: 2.1ex !important;
            padding-right: 2.1ex !important;

            & > input.search {
                height: 100%;
                line-height: unset !important;
                padding: 1em 3em !important;
                padding-left: 2.1ex;
                padding-right: 2.1ex;
                z-index: unset !important;
            }

            .menu {
                &.visible.transition {
                    display: block !important;
                    visibility: visible !important;
                    /*  backface-visibility: @backfaceVisibility;
                transform: @use3DAcceleration;*/
                }
            }
        }
    }
}

.salvationHeading {
    font-size: 40px !important;
    line-height: 49px !important;
}

.salvationDescription {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-top: 16px !important;
}

.salvationTitleBreak {
    display: none !important;
}

.salvationStartButton {
    margin-top: 96px !important;
}

.salvationDarkFont {
    color: #777c88 !important;
}

.salvation-campus {
    input {
        max-height: 38px !important;
    }

    h2 {
        line-height: 29.26px !important;
    }

    .p-t-spacer-lg {
        padding-top: 0 !important;
    }

    .p-b-spacer-lg {
        padding-bottom: 0 !important;
    }

    #cc-salvation {
        border-radius: 10px;
    }

    button {
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .canadaLink {
        padding-top: 0 !important;
    }

    .field {
        margin-bottom: 0 !important;
    }

    p {
        margin-bottom: 28.8px !important;
    }
}

.salvationReset {
    margin-top: 64px !important;
}

div[name="campus"] {
    align-items: center !important;
    border-radius: 10px !important;
    display: flex !important;
    height: 38px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.SalvationCampusSelector {
    .text-content {
        min-height: auto !important;
        text-align: left !important;
    }
}

@media only screen and (max-width: 900px) {
    .salvationHeading {
        font-size: 24px !important;
        line-height: 29.26px !important;
    }

    .salvationDescription {
        font-size: 18px !important;
        line-height: 25.89px !important;
        margin-top: 8px !important;
    }

    .salvationWrapper {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .salvationTitleBreak {
        display: block !important;
    }

    .salvationStartButton {
        margin-top: 80px !important;
    }

    .salvation-campus {
        #cc-salvation {
            border-radius: 10px;
        }

        button {
            margin-bottom: 24px;
            margin-top: 24px;
        }

        .canadaLink {
            padding-top: 0 !important;
        }

        h2 {
            margin-bottom: 8px;
        }
    }

    .SalvationCampusSelector {
        .campus-options-wrapper {
            max-width: 430px;
        }
        h1 {
            font-size: 24px !important;
            line-height: 29.26px !important;
        }

        .container {
            p {
                font-size: 16px !important;
                margin-bottom: 20px !important;
            }
        }

        p {
            font-size: 16px !important;
        }

        .text-content {
            min-height: auto !important;

            h3 {
                font-size: 18px !important;
            }
        }
    }
}
