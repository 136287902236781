/* ----------------------------------------------
 * Generated by Animista on 2019-5-21 11:23:32
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(-600px) rotateX(30deg) scale(6.5);
        transform: translateY(-600px) rotateX(30deg) scale(6.5);
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% -500px;
        transform-origin: 50% -500px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(-600px) rotateX(30deg) scale(6.5);
        transform: translateY(-600px) rotateX(30deg) scale(6.5);
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% -500px;
        transform-origin: 50% -500px;
        opacity: 1;
    }
}

.slide-in-elliptic-top-bck {
    -webkit-animation: slide-in-elliptic-top-bck 0.7s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-elliptic-top-bck 0.7s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-tr {
    0% {
        -webkit-transform: translateY(-1000px) translateX(1000px);
        transform: translateY(-1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-tr {
    0% {
        -webkit-transform: translateY(-1000px) translateX(1000px);
        transform: translateY(-1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

.slide-in-tr {
    -webkit-animation: slide-in-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: slide-in-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right-delay {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        500ms both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 500ms
        both;
}

@-webkit-keyframes scale-up-hor-right {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

@keyframes scale-up-hor-right {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

.scale-up-hor-right {
    -webkit-animation: scale-up-hor-right 0.4s
        cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slide-in-blurred-right {
    -webkit-animation: slide-in-blurred-right 0.5s
        cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: slide-in-blurred-right 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@keyframes slide-out-blurred-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

.slide-out-blurred-right {
    -webkit-animation: slide-out-blurred-right 0.45s
        cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    animation: slide-out-blurred-right 0.45s
        cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slide-in-blurred-top {
    -webkit-animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1)
        both;
    animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

@keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

.slide-out-blurred-top {
    -webkit-animation: slide-out-blurred-top 0.45s
        cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    animation: slide-out-blurred-top 0.45s
        cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}
