@keyframes scale-up-center-egroup {
    0% {
        transform: translate(-52%, -22%) scale(0.5);
    }

    50% {
        transform: translate(-52%, -22%) scale(1.1);
    }

    100% {
        transform: translate(-52%, -22%) scale(1);
    }
}

@keyframes scale-up-center-campus {
    0% {
        transform: translate(-55.5%, -43%) scale(0.5);
    }

    50% {
        transform: translate(-55.5%, -43%) scale(1.1);
    }

    100% {
        transform: translate(-55.5%, -43%) scale(1);
    }
}
