.watch-party {
    .button:not(.ec-no-background) {
        background-color: $eteams-color;
    }

    .ec-radio {
        background-color: transparent;

        label {
            margin: 0;
            position: initial;
        }
    }

    input[type="radio"] {
        display: none;
    }

    .watchParty-orientation-campus-select {
        height: 48px;
        padding: 0;
    }

    .watchParty-orientation-campus-select,
    .nextBtn {
        border-radius: 8px;
    }

    .watchParty-orientation-campus-select select {
        outline: none;
        padding: 0;
        padding-left: 8px;
    }

    .watchParty-orientation-campus-select::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        font-size: 20px;
        font-weight: 100;
        opacity: 0.35;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 13px;
    }

    .search-grey {
        background-color: $gray-form-elements;
    }

    div.fields > :last-child {
        margin-bottom: 1em;
    }

    .help-text {
        color: #ff671f;
    }

    div.watchParty-orientation-date-select,
    div.egroup-leader-date-select {
        padding: 0;
    }

    .watchParty-orientation-date-select > label,
    .egroup-leader-date-select > label {
        display: block;
        margin: 0;
        padding: 13px;
    }
}
