// ----------------------------------------
// Absolute Horizontal and Vertical Spacing
// Ether used the core typeface to generate sizes for the system spacing.
// $cap is defined as the x-height of the $b0 type size
// ----------------------------------------

// Create spacing sizes tied to type

$cap: 0.9ex; // Cap-height of the $b0 font size

$spacer-none: 0; // 0
$spacer-2xs: ceil($cap * 0.5); // 5px
$spacer-xs: $cap; // 9px
$spacer-sm: round($cap * 1.5); // 14px
$spacer-md: round($cap * 2.5); // 23px
$spacer-lg: round($cap * 3.25);
$spacer-xl: round($cap * 4); // 36px
$spacer-2xl: round($cap * 6.5); // 59px
$spacer-3xl: round($cap * 10.5); // 95px
$spacer-4xl: round($cap * 15.5); // 116px

$sizes: (
    "none": $spacer-none,
    "2xs": $spacer-2xs,
    "xs": $spacer-xs,
    "sm": $spacer-sm,
    "md": $spacer-md,
    "lg": $spacer-lg,
    "xl": $spacer-xl,
    "2xl": $spacer-2xl,
    "3xl": $spacer-3xl,
    "4xl": $spacer-4xl,
);

// margin
@each $size, $value in $sizes {
    .m-spacer-#{$size} {
        margin: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin: $value;
        }
    }

    .m-t-spacer-#{$size} {
        margin-top: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-top: $value;
        }
    }

    .m-b-spacer-#{$size} {
        margin-bottom: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-bottom: $value;
        }
    }

    .m-l-spacer-#{$size} {
        margin-left: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-left: $value;
        }
    }

    .m-r-spacer-#{$size} {
        margin-right: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-right: $value;
        }
    }

    .m-x-spacer-#{$size} {
        margin-left: $value * 0.7;
        margin-right: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-left: $value;
            margin-right: $value;
        }
    }

    .m-y-spacer-#{$size} {
        margin-bottom: $value * 0.7;
        margin-top: $value * 0.7;

        @media screen and (min-width: 992px) {
            margin-bottom: $value;
            margin-top: $value;
        }
    }
}

@each $size, $value in $sizes {
    .mob-m-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin: $value !important;
        }
    }

    .mob-m-t-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-top: $value !important;
        }
    }

    .mob-m-b-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-bottom: $value !important;
        }
    }

    .mob-m-l-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-left: $value !important;
        }
    }

    .mob-m-r-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-right: $value !important;
        }
    }

    .mob-m-x-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-left: $value !important;
            margin-right: $value !important;
        }
    }

    .mob-m-y-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            margin-bottom: $value !important;
            margin-top: $value !important;
        }
    }
}

// padding
@each $size, $value in $sizes {
    .p-spacer-#{$size} {
        padding: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding: $value * 0.7;
        }
    }

    .p-t-spacer-#{$size} {
        padding-top: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-top: $value * 0.7;
        }
    }

    .p-b-spacer-#{$size} {
        padding-bottom: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-bottom: $value * 0.7;
        }
    }

    .p-l-spacer-#{$size} {
        padding-left: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-left: $value * 0.7;
        }
    }

    .p-r-spacer-#{$size} {
        padding-right: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-right: $value * 0.7;
        }
    }

    .p-x-spacer-#{$size} {
        padding-left: $value * 0.7;
        padding-right: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-left: $value * 0.7;
            padding-right: $value * 0.7;
        }
    }

    .p-y-spacer-#{$size} {
        padding-bottom: $value * 0.7;
        padding-top: $value * 0.7;

        @media screen and (min-width: 992px) {
            padding-bottom: $value * 0.7;
            padding-top: $value * 0.7;
        }
    }
}

@each $size, $value in $sizes {
    .mob-p-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding: $value !important;
        }
    }

    .mob-p-t-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-top: $value !important;
        }
    }

    .mob-p-b-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-bottom: $value !important;
        }
    }

    .mob-p-l-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-left: $value !important;
        }
    }

    .mob-p-r-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-right: $value !important;
        }
    }

    .mob-p-x-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-left: $value !important;
            padding-right: $value !important;
        }
    }

    .mob-p-y-spacer-#{$size} {
        @media screen and (max-width: 991px) {
            padding-bottom: $value !important;
            padding-top: $value !important;
        }
    }
}

// Mixins for the spacers.
// usage: @include m-t-spacer-($spacer-md);

@mixin m-t-spacer-($value) {
    margin-top: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-top: $value;
    }
}

@mixin m-b-spacer-($value) {
    margin-bottom: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-bottom: $value;
    }
}

@mixin m-l-spacer-($value) {
    margin-left: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-left: $value;
    }
}

@mixin m-r-spacer-($value) {
    margin-right: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-right: $value;
    }
}

@mixin m-x-spacer-($value) {
    margin-left: $value * 0.7;
    margin-right: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-left: $value;
        margin-right: $value;
    }
}

@mixin m-y-spacer-($value) {
    margin-bottom: $value * 0.7;
    margin-top: $value * 0.7;

    @media screen and (min-width: 992px) {
        margin-bottom: $value;
        margin-top: $value;
    }
}

@mixin p-t-spacer-($value) {
    padding-top: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-top: $value;
    }
}

@mixin p-b-spacer-($value) {
    padding-bottom: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-bottom: $value;
    }
}

@mixin p-l-spacer-($value) {
    padding-left: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-left: $value;
    }
}

@mixin p-r-spacer-($value) {
    padding-right: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-right: $value;
    }
}

@mixin p-x-spacer-($value) {
    padding-left: $value * 0.7;
    padding-right: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-left: $value;
        padding-right: $value;
    }
}

@mixin p-y-spacer-($value) {
    padding-bottom: $value * 0.7;
    padding-top: $value * 0.7;

    @media screen and (min-width: 992px) {
        padding-bottom: $value;
        padding-top: $value;
    }
}
