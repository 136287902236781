.route.map-container {
    padding: 0;

    main {
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
    }

    .egroups-map {
        height: calc(100vh - #{$menu-height-mobile});

        @media screen and (min-width: 992px) {
            height: calc(100vh - #{$menu-height});
        }

        .map-wrapper {
            height: calc(100vh - #{$menu-height-mobile});

            @media screen and (min-width: 992px) {
                height: calc(100vh - #{$menu-height});
            }

            .cluster {
                cursor: pointer;
                font-size: 18px;
                height: 64px;
                left: 50%;
                line-height: 29px;
                margin: -32px 0 0 -32px;
                position: absolute;
                top: 50%;
                width: 64px;

                .cluster-inner {
                    margin-top: -14px;
                    top: 50%;
                }
            }

            &.filters-bottom {
                height: calc(100vh - #{$menu-height} - 43px);
            }

            .point-wrapper,
            .point,
            .custom-point {
                height: 64px;
                margin: -32px 0 0 -32px;
                position: relative;
                width: 64px;
            }

            .point,
            .custom-point {
                cursor: pointer;
                left: 50%;
                position: absolute;
                top: 50%;
            }

            #infoWindow-container {
                bottom: 0;
                top: initial;

                &.efam {
                    transform: translate(-52%, -30%);

                    &.sayHiForm {
                        transform: translate(-52%, -19.5%);

                        &.smaller {
                            transform: translate(-52%, -56.5%);
                        }

                        .success {
                            color: #6dd400;
                        }
                    }

                    &.only-name {
                        transform: translate(-52.25%, -70%);
                    }
                }

                &.eGroup {
                    transform: translate(-52%, -22%);
                }

                &.watchparty {
                    transform: translate(-53%, -41%);
                }

                &.campus {
                    transform: translate(-55.5%, -43%);
                }

                .egroups-leader-no-image {
                    display: inline-block;
                    font-family: $secondary-font-family;
                    font-weight: bold;
                    letter-spacing: -4px;
                    text-transform: uppercase;

                    &.gradient1 {
                        background: rgb(252, 227, 138);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(252, 227, 138, 1) 0%,
                                rgba(243, 129, 129, 1) 100%
                            );
                    }

                    &.gradient2 {
                        background: rgb(245, 78, 162);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(245, 78, 162, 1) 0%,
                                rgba(255, 118, 118, 1) 100%
                            );
                    }

                    &.gradient3 {
                        background: rgb(23, 234, 217);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(23, 234, 217, 1) 0%,
                                rgba(96, 120, 234, 1) 100%
                            );
                    }

                    &.gradient4 {
                        background: rgb(98, 39, 116);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(98, 39, 116, 1) 0%,
                                rgba(197, 51, 100, 1) 100%
                            );
                    }

                    &.gradient5 {
                        background: rgb(113, 23, 234);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(113, 23, 234, 1) 0%,
                                rgba(234, 96, 96, 1) 100%
                            );
                    }

                    &.gradient6 {
                        background: rgb(66, 230, 149);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(66, 230, 149, 1) 0%,
                                rgba(59, 178, 184, 1) 100%
                            );
                    }

                    &.gradient7 {
                        background: rgb(240, 47, 194);
                        background: linear-gradient
                            (
                                45deg,
                                rgba(240, 47, 194, 1) 0%,
                                rgba(96, 148, 234, 1) 100%
                            );
                    }
                }
            }

            .custom-point {
                &.eGroupPoint {
                    background-image: url("../images/eGroups_marker.png");
                }
            }

            .loading {
                background: rgba(255, 255, 255, 0.4);
                color: #000;

                .loading-spin {
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 50px;
                    margin-left: -25px;
                    margin-top: -25px;
                }
            }

            .infoWindow {
                width: 350px;

                &.eGroup {
                    width: 315px;

                    .egroup-leader-img-ontop:first-of-type {
                        left: 0;
                    }

                    .egroup-leader-img-ontop:nth-of-type(2) {
                        left: -5%;
                    }

                    .egroup-leader-img-ontop:nth-of-type(3) {
                        left: -10%;
                    }

                    .egroup-leader-img-ontop:nth-of-type(4) {
                        left: -15%;
                    }
                    .egroup-leader-img-ontop:nth-of-type(5) {
                        left: -25%;
                    }
                    .egroup-leader-img-ontop:nth-of-type(6) {
                        left: -30%;
                    }
                    .egroup-leader-img-ontop:nth-of-type(7) {
                        left: -35%;
                    }
                    .egroup-leader-img-ontop:nth-of-type(8) {
                        left: -40%;
                    }
                    .egroup-leader-img-ontop:nth-of-type(9) {
                        left: -45%;
                    }
                }

                &.watchparty {
                    width: 275px;
                }

                .join-community {
                    max-width: 75%;
                }

                .close-window button {
                    cursor: pointer;
                }

                &::after {
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 18px solid #fff;
                    bottom: -18px;
                    content: "";
                    height: 0;
                    margin-right: -10px;
                    position: absolute;
                    right: 50%;
                    width: 0;
                }

                .egroup-image {
                    img {
                        max-height: 130px;
                        width: 100%;
                    }
                }

                .egroup-name {
                    width: 100%;

                    h2 {
                        font-size: 16px;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .egroup-frequency {
                    h3 {
                        color: $dark-gray;
                        font-size: 12px;
                        margin: 0;
                        opacity: 0.5;
                    }
                }

                .details-button {
                    button {
                        margin: 14px 0;
                    }
                }

                .sayHiLoading {
                    height: 150px;
                }
            }

            .map-searchbox {
                top: 1em;
                width: 90vw;

                @include bp-md-min {
                    left: 350px;
                    width: 250px;
                }

                input {
                    min-width: 175px;
                    width: 100%;

                    @include bp-md-min {
                        width: 20vw;
                    }
                }
            }
        }

        .point-wrapper .egroup-logo {
            color: #fff;
            height: auto;
            left: 10%;
            position: absolute;
            text-align: center;
            top: 15%;
            width: 36px;
        }

        .custom-point {
            height: 48px !important;
            width: 48px !important;
        }

        .custom-point.efam {
            background-color: #acd7ee;
            border: 4px solid #fff;
        }

        .custom-point.efam .fas {
            color: #fff;
            left: 25%;
            position: absolute;
            text-align: center;
            top: 25%;
        }

        .custom-point.watch-party {
            background-color: #ff671f;
            border: 4px solid #fff;
        }

        .custom-point.watch-party .fas {
            color: #fff;
            left: 19%;
            position: absolute;
            text-align: center;
            top: 28%;
        }

        .custom-point.egroup {
            background-color: #011b5a;
            box-shadow: 0 0 0 10px rgba(1, 27, 90, 0.25);
        }

        .filters {
            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
            z-index: 101;

            @media (max-width: 767px) {
                padding-top: $spacer-sm !important;
            }
        }
    }
}
