$screen-lg-min: 1200px;
$screen-md-max: $screen-lg-min - 1;
$screen-md-min: 992px;
$screen-sm-max: $screen-md-min - 1;
$screen-sm-min: 768px;
$screen-xs-max: $screen-sm-min - 1;
$screen-xs-min: 576px;

@mixin bp-iphone-max {
    @media (max-width: 380px) {
        @content;
    }
}

@mixin bp-xs-min {
    @media (min-width: $screen-xs-min) {
        @content;
    }
}

@mixin bp-xs-max {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin bp-sm-min {
    @media (min-width: $screen-sm-min) {
        @content;
    }
}

@mixin bp-sm-max {
    @media (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin bp-md-min {
    @media (min-width: $screen-md-min) {
        @content;
    }
}

@mixin bp-md-max {
    @media (max-width: $screen-md-max) {
        @content;
    }
}

@mixin bp-lg-min {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}
