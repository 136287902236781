hr {
    border-color: hsla(0, 0%, 9%, 0.15);
}

.fb-blue {
    background-color: #1877f2;
}

.pac-container {
    border-radius: 8px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);

    .pac-item {
        font-size: 16px;
        padding: 10px;

        .pac-item-query {
            font-size: 16px;
        }
    }

    &::content {
        background-position-x: calc(100% - 11px);
        margin-bottom: 9px;
    }
}

.pac-icon {
    display: none;
}

@import "./variables";
@import "./autoload/sass-flexbox/main";
@import "./autoload/animation";

@import "./common/legacy";
@import "./common/mixins";
@import "./common/global";
@import "./common/animations";

@import "./components/buttons";
// @import './components/menu';
@import "./components/main";
@import "./components/home";
@import "./components/maps";
@import "./components/filters";

@import "./components/egroups";
@import "./components/collections";
@import "./components/baptism";
@import "./components/eteams";
@import "./components/salvation";
@import "./components/vip";
@import "./components/watch-party";
@import "./components/events";

@import "./components/form";
@import "./components/egroup-leader";
