body {
    .wrap {
        min-height: calc(100vh - #{$menu-height-mobile});

        @media screen and (min-width: 992px) {
            min-height: calc(100vh - #{$menu-height});
        }
    }
}

.route-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
}

.route-wrapper > div {
    height: 100%;
    width: 100%;
}

.ec-dropdown {
    position: relative;

    select {
        appearance: none;
        border-radius: 8px;
        color: $dark-gray;
        height: 100%;
        outline: 0;
        width: 100%;
    }

    option {
        padding: 0;
    }

    &.error {
        background: rgb(255, 242, 246);

        select {
            background: rgb(255, 242, 246);
        }
    }

    &::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        font-size: 20px;
        font-weight: 100;
        opacity: 0.35;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 13px;
    }
}

.ec-radio {
    background-color: $main-gray;
    border: 0;
    border-radius: 8px;
    color: $dark-gray;
    width: 100%;

    label,
    .label-inner {
        color: hsla(0, 0%, 9%, 0.5);
        position: relative;
        width: 100%;
    }

    &.checked {
        label,
        .label-inner {
            color: hsla(0, 0%, 9%, 1);
        }
    }

    input[type="radio"] {
        display: none;
    }
}

.button {
    &.egroups {
        background-color: $egroups-color !important;
    }

    &.baptism {
        background-color: $base-baptism !important;
    }

    &.eteams,
    &.events {
        background-color: $eteams-color !important;
    }

    &.watch-party {
        background-color: $ecOrange !important;
    }
}

.success-background-image {
    min-height: 185px;

    &.egroup {
        background-image: url("../images/eGroups.jpg");
    }

    &.eteams {
        background-image: url("../images/eTeams.jpg");
    }

    &.baptism {
        background-image: url("../images/Baptisms.jpg");
    }
}

.salvation-page,
.salvation-success {
    background-image: url("https://dv9pmwasxvawj.cloudfront.net/images/connection_center/salvation/Congratulations_bg.jpg");
}

@media only screen and (max-width: 900px) {
    .salvation-page {
        background-image: url("https://dv9pmwasxvawj.cloudfront.net/images/connection_center/salvation/cc_salvation_congratulations_mobile_bg-v2.jpg");
    }
    .salvation-success {
        background-color: #2a2446;
        background-image: none;
    }
}

a.ui.button {
    display: block;
}

@media (min-width: 1200px) {
    .container.reduced-container {
        max-width: 450px;
    }
}

.small-img {
    height: 72px;
    max-width: 100%;
    width: 72px;
}

.medium-img {
    height: 100px;
    max-width: 100%;
    width: 100px;
}

.egroup-green-background {
    background-color: #bfd440;
}

// Google Maps Searchbox styling
.pac-container {
    border-radius: 8px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);

    .pac-item {
        font-size: 16px;
        padding: 10px;

        .pac-item-query {
            font-size: 16px;
        }
    }

    &::after {
        background-position-x: calc(100% - 11px);
        margin-bottom: 9px;
    }
}

.pac-icon {
    display: none;
}

.ec-error {
    small {
        font-size: 12px;
    }

    textarea {
        background-color: rgb(255, 242, 246);
    }
}

.form-loading {
    height: 50px;
}

//loading
/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
    box-sizing: border-box;
    position: relative;
}

.la-ball-clip-rotate {
    color: #fff;
    display: block;
    font-size: 0;
}

.la-ball-clip-rotate.la-dark {
    color: #333;
}

.egroups .la-ball-clip-rotate.la-color {
    color: $egroups-color;
}

.events .la-ball-clip-rotate.la-color,
.eteams .la-ball-clip-rotate.la-color {
    color: $eteams-color;
}

.watch-party .la-ball-clip-rotate.la-color {
    color: $ecOrange;
}

.baptism .la-ball-clip-rotate.la-color {
    color: $base-baptism;
}

.salvation .la-ball-clip-rotate.la-color {
    color: $base-salvation;
}

.la-ball-clip-rotate > div {
    background-color: currentColor;
    border: 0 solid currentColor;
    display: inline-block;
    float: none;
}

.la-ball-clip-rotate {
    height: 32px;
    width: 32px;
}

.la-ball-clip-rotate > div {
    animation: ball-clip-rotate 0.75s linear infinite;
    background: transparent;
    border-bottom-color: transparent;
    border-radius: 100%;
    border-width: 2px;
    height: 32px;
    width: 32px;
}

.la-ball-clip-rotate.la-sm {
    height: 16px;
    width: 16px;
}

.la-ball-clip-rotate.la-sm > div {
    border-width: 1px;
    height: 16px;
    width: 16px;
}

.la-ball-clip-rotate.la-2x {
    height: 64px;
    width: 64px;
}

.la-ball-clip-rotate.la-2x > div {
    border-width: 4px;
    height: 64px;
    width: 64px;
}

.la-ball-clip-rotate.la-3x {
    height: 96px;
    width: 96px;
}

.la-ball-clip-rotate.la-3x > div {
    border-width: 6px;
    height: 96px;
    width: 96px;
}

.extra-high-minus-menu {
    min-height: calc(100vh - #{$menu-height-mobile});

    @media screen and (min-width: 992px) {
        min-height: calc(100vh - #{$menu-height});
    }
}

main.main {
    width: 100% !important;
}

.change-details .container.extra-high,
.success-screen .container.extra-high {
    height: 100%;
}

::placeholder {
    color: #a5a7ab;
}

.generic-overlay {
    background-color: rgba(255, 255, 255, 0.5);
}

.font-italic {
    font-style: italic;
}

#page-header {
    height: $mostlyFullvh - 10vh;

    h1,
    h2,
    h3 {
        line-height: 1.2;
    }
}

.max-w-200 {
    max-width: calculaterem(200px);
}

.max-w-500 {
    max-width: calculaterem(500px);
}

.large-card {
    min-height: calculaterem(650px);
}

.medium-card {
    min-height: calculaterem(550px);
}

.small-card {
    min-height: calculaterem(400px);
}

.xsmall-card {
    min-height: calculaterem(280px);
}

.announcement-card {
    min-height: calculaterem(120px);
}

.text-trans-alt-dark-grey {
    color: hsla(0, 0%, 9%, 0.4);
}

.cut-column {
    @include content-columns(1, 0);

    @include bp-md-min {
        @include content-columns(2, 75px);
    }
}

.fsSubmitButton {
    display: none;
}

.container {
    @include bp-xs-min {
        max-width: 540px;
    }

    @include bp-sm-min {
        max-width: 720px;
    }

    @include bp-md-min {
        max-width: 960px;
    }

    @include bp-lg-min {
        max-width: 1140px;
    }

    &,
    &-fluid {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: $fullPercent;
    }
}

.success-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 12px;

    grid-template-areas:
        "row1Col1"
        "row1Col2"
        "row3Col1";

    @media (min-width: 991px) {
        grid-template-areas: "row1Col1 row1Col2 row1Col3 ";
    }
}
