.hover-grow {
    @include transition(all 0.3s ease);

    &:hover {
        @include transform(scale(1.03));
    }
}

.hover-invert {
    @include transition(all 0.2s ease);
}

.hover-shadow {
    @include transition(box-shadow 0.3 ease-in);

    &:hover {
        @extend %ec-box-shadow;
    }
}
