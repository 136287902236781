/** Global */
$fullPercent: 100%;
$mostlyFullPercent: 90%;
$threeQuartersPercent: 75%;
$halfPercent: 50%;
$thirdPercent: 33%;
$quarterPercent: 25%;
$fullvh: 100vh;
$mostlyFullvh: 90vh;
$threeQuartersvh: 75vh;
$halfvh: 50vh;
$quartervh: 25vh;
$fullvw: 100vw;
$mostlyFullvw: 90vw;
$threeQuartersvw: 75vw;
$halfvw: 50vh;
$quartervw: 25vw;

/** Colors */
$dark: #000;
$white: #fff;
$offWhite: #f4f5f7;
$translucentwhite: rgba(255, 255, 255, 0.25);
$lightgrey: #efefef;
$altLightGrey: #919193;
$translucentLightGrey: rgba(244, 245, 247, 0.5);
$translucentLightGreyAlt: rgba(60, 60, 67, 0.6);
$grey: #d7dacf;
$darkGrey: #181818;
$translucentDarkGrey: rgba(24, 24, 24, 0.15);
$altDarkGrey: #18181c;
$red: #ff0048;
$youtubeRed: #ff0800;
$lightBlue: #1da1f2;
$altLightBlue: #7ac2f7;
$blue: #3b5998;
$egroupsBlue: #001b59;
$green: #6ef23a;
$success: #50c76e;
$ecOrange: #ff671f;
$transparent: transparent;
$egroups-color: #ff671f;
$eteams-color: #ff671f;
$base-baptism: #1da1f2;
$base-salvation: #1da1f2;
$success-green: #59c900;

$focusBlue: #007aff;

$active-state: #69acff;

$main-gray: #efefef;
$gray-form-elements: #f4f5f7;
$dark-gray: #18181c;
$white: #fff;

$primary-font: "Open Sans", sans-serif;
$primary-sub-title-letter-spacing: -0.5px;
$primary-letter-spacing: -0.25px;
$secondary-font: montserrat, sans-serif;
$primary-font-family: Open Sans, sans-serif;
$secondary-font-family: Montserrat;
$secondary-letter-spacing: -0.2px;
$sec-title-letter-spacing: -1px;
$sec-sub-title-letter-spacing: -0.5px;
$icons-font-family: "Font Awesome 5 Pro";
$fa-icon-font: "Font Awesome 5 Pro";

$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$font-style-normal: normal;
$font-style-italic: italic;

$menu-height-mobile: 57px;
$menu-height: 84.94px;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

/** Hamburger icon */
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-types: (squeeze);
