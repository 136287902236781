@import "./mixins/modal-scale-up";

.scale-up-center {
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.animatable {
    /* initially pause animatable objects their animations */
    animation-play-state: paused;
    /* initially hide animatable objects */
    visibility: hidden;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-19 12:6:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
    box-sizing: border-box;
    position: relative;
}

.la-ball-clip-rotate {
    color: #fff;
    display: block;
    font-size: 0;
}

.la-ball-clip-rotate.la-dark {
    color: #333;
}

.la-ball-clip-rotate > div {
    background-color: currentColor;
    border: 0 solid currentColor;
    display: inline-block;
    float: none;
}

.la-ball-clip-rotate {
    height: 32px;
    width: 32px;
}

.la-ball-clip-rotate > div {
    animation: ball-clip-rotate 0.75s linear infinite;
    background: transparent;
    border-bottom-color: transparent;
    border-radius: 100%;
    border-width: 2px;
    height: 32px;
    width: 32px;
}

.la-ball-clip-rotate.la-sm {
    height: 16px;
    width: 16px;
}

.la-ball-clip-rotate.la-sm > div {
    border-width: 1px;
    height: 16px;
    width: 16px;
}

.la-ball-clip-rotate.la-2x {
    height: 64px;
    width: 64px;
}

.la-ball-clip-rotate.la-2x > div {
    border-width: 4px;
    height: 64px;
    width: 64px;
}

.la-ball-clip-rotate.la-3x {
    height: 96px;
    width: 96px;
}

.la-ball-clip-rotate.la-3x > div {
    border-width: 6px;
    height: 96px;
    width: 96px;
}
/*
  * Animation
  */
@keyframes ball-clip-rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*
  * ----------------------------------------
  * animation scale-up-center
  * ----------------------------------------
  */

@keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@include keyframes(fadeInDown) {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@include keyframes(fadeIn) {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include keyframes(bounceInLeft) {
    0% {
        opacity: 0;
        transform: translateX(-2000px);
    }

    60% {
        transform: translateX(20px);
    }

    80% {
        transform: translateX(-5px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@include keyframes(bounceInRight) {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }

    60% {
        transform: translateX(-20px);
    }

    80% {
        transform: translateX(5px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@include keyframes(fadeInUp) {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@include keyframes(bounceIn) {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }

    50% {
        transform: scale(1.05);
    }

    70% {
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@include keyframes(moveUp) {
    0% {
        opacity: 1;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@include keyframes(fadeBgColor) {
    0% {
        background: none;
    }

    70% {
        background: none;
    }

    100% {
        background: #464646;
    }
}

/* show objects being animated */
.animated {
    animation-fill-mode: both;
    animation-play-state: running;
    visibility: visible;

    &.fadeBgColor {
        @include animation("fadeBgColor 0.5s 1");
    }

    &.bounceIn {
        @include animation("bounceIn 0.5s 1");
    }

    &.bounceInRight {
        @include animation("bounceInRight 0.5s 1");
    }

    &.bounceInLeft {
        @include animation("bounceInLeft 0.5s 1");
    }

    &.fadeIn {
        @include animation("fadeIn 0.5s 1");
    }

    &.fadeInDown {
        @include animation("fadeInDown 0.5s 1");
    }

    &.fadeInUp {
        @include animation("fadeInUp 0.5s 1");
    }

    &.moveUp {
        @include animation("moveUp 0.5s 1");
    }

    &.scale-up-center {
        @include animation("scale-up-center 0.5s 1");

        &.eGroup {
            @include animation("scale-up-center-egroup 0.5s 1");
        }

        &.campus {
            @include animation("scale-up-center-campus 0.5s 1");
        }
    }
}

@keyframes shimmer-inverted {
    0% {
        background-position: -250px 0;
    }

    100% {
        background-position: 250px 0;
    }
}

.animate-shimmer-inverted {
    animation: shimmer-inverted 2s infinite;
    background: linear-gradient(to right, #e9e9ec 4%, #dedee3 25%, #e9e9ec 36%);
    background-size: 800px 100%;
}

@keyframes shimmer {
    0% {
        background-position: -250px 0;
    }

    100% {
        background-position: 250px 0;
    }
}

.animate-shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #e9e9ec 4%, #dedee3 25%, #e9e9ec 36%);
    background-size: 800px 100%;
}

.comment-loading-shimmer {
    height: 10px;
    margin-top: 20px;
}

.element-loading-shimmer {
    height: 10px;
}

.egroups-home-loading-element {
    height: 170px;
    width: 272px;
}

.show-animation-enter {
    opacity: 0.01;
    transform: scale(0.9);
}

.show-animation-enter.show-animation-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease-in;
}
