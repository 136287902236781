.baptism {
    .button:not(.ec-no-background) {
        background-color: $base-baptism;
    }

    .baptism-campus-select {
        padding: 0;
    }

    .baptism-campus-select > select {
        appearance: none;
        background-color: $gray-form-elements;
        opacity: 0.5;
        outline: none;
        padding-bottom: 1.4ex;
        padding-left: 8px;
        padding-top: 1.4ex;
    }

    .baptism-campus-select::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        font-size: 20px;
        font-weight: 100;
        opacity: 0.35;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 12px;
    }

    .button {
        outline: none;
    }

    .share-story {
        background-color: #fff !important;
        border: solid 2px #ff671f;
        color: #ff671f;
    }

    .help-text {
        color: #1da1f2;
    }
}
