html {
    height: 100vh;
    width: 100vw;
}

html * {
    box-sizing: border-box;
}

body {
    margin: 0;
}

/* --------------
Global Styles
----------------*/
.hide {
    display: none;
}

.show {
    display: block;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.disappear {
    opacity: 0;
}

.half-here {
    opacity: 0.5;
}

.appear {
    opacity: 1;
}

.abs-pos {
    position: absolute;
}

.static-pos {
    position: static;
}

.rel-pos {
    position: relative;
}

.fix-pos {
    position: fixed;
}

.sticky-pos {
    position: sticky;
}

.grid {
    display: grid;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap,
.wrap-mobile {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-align-center {
    align-items: center;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-start {
    justify-content: flex-start;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-justify-spacearound,
.flex-justify-around {
    justify-content: space-around;
}

.flex-justify-spacebetween,
.flex-justify-between {
    justify-content: space-between;
}

.flex-justify-even {
    justify-content: space-evenly;
}

.flex-three-column,
.flex-three,
.flex-big-column {
    flex-basis: 100%;
}

.flex-two-column {
    flex-basis: 100%;
}

@media screen and (max-width: 1100px) {
    .show-mobile {
        display: block;
    }

    .mobile-hide {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    .double-padding {
        padding: 2em 3em;
    }

    .flex-two-column {
        flex-basis: 45%;
    }

    .flex-three-column {
        flex-basis: 32.5%;
    }

    .flex-three {
        flex-basis: 45%;
    }

    .background-fixed {
        background-attachment: scroll;
    }

    .text-sm {
        font-size: 11px;
    }
}

@media screen and (min-width: 1100px) {
    .wrap-mobile {
        flex-wrap: nowrap;
    }

    .flex-two-column {
        flex-basis: 46%;
    }

    .flex-three {
        flex: 0 0 30%;
    }

    .flex-big-column {
        flex-basis: 54%;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.uptop {
    top: 0;
}

.downlow {
    bottom: 0;
}

.stayleft {
    left: 0;
}

.stayright {
    right: 0;
}

.way-back {
    z-index: -1;
}

.get-behind {
    z-index: 1;
}

.pull-forward {
    z-index: 2;
}

.more-forward {
    z-index: 10;
}

.ahead-of-most {
    z-index: 50;
}

.way-forward {
    z-index: 100;
}

.all-the-way-forward {
    z-index: 99999;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.no-transform {
    text-transform: none;
}

.ec-top-border-radius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &-xxl {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
    }
}

.ec-bottom-border-radius {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ec-rounded {
    border-radius: 50px;
}

.full-radius {
    border-radius: 50%;
}

.full-width,
.ec-button {
    max-width: 100%;
    width: 100%;
}

.full-height {
    height: 100%;
}

.extra-wide {
    width: 100vw;
}

.extra-high {
    height: 100vh;
}

.ec-max-width {
    max-width: 1400px;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.double-bottom-margin {
    margin-bottom: 2em;
}

.double-top-margin {
    margin-top: 2em;
}

.double-left-margin {
    margin-left: 2em;
}

.double-right-margin {
    margin-right: 2em;
}

.triple-bottom-margin {
    margin-bottom: 3em;
}

.triple-top-margin {
    margin-top: 3em;
}

.triple-left-margin {
    margin-left: 3em;
}

.triple-right-margin {
    margin-right: 3em;
}

.quad-bottom-margin {
    margin-bottom: 4em;
}

.quad-top-margin {
    margin-top: 4em;
}

.quad-left-margin {
    margin-left: 4em;
}

.quad-right-margin {
    margin-right: 4em;
}

.bottom-margin {
    margin-bottom: 1em;
}

.top-margin {
    margin-top: 1em;
}

.left-margin {
    margin-left: 1em;
}

.right-margin {
    margin-right: 1em;
}

.slight-bottom-margin {
    margin-bottom: 0.5em;
}

.slight-top-margin {
    margin-top: 0.5em;
}

.slight-left-margin {
    margin-left: 0.5em;
}

.slight-right-margin {
    margin-right: 0.5em;
}

.double-bottom-padding {
    padding-bottom: 2em;
}

.double-top-padding {
    padding-top: 2em;
}

.double-left-padding {
    padding-left: 2em;
}

.double-right-padding {
    padding-right: 2em;
}

.triple-bottom-padding {
    padding-bottom: 3em;
}

.triple-top-padding {
    padding-top: 3em;
}

.triple-left-padding {
    padding-left: 3em;
}

.triple-right-padding {
    padding-right: 3em;
}

.quad-bottom-padding {
    padding-bottom: 4em;
}

.quad-top-padding {
    padding-top: 4em;
}

.quad-left-padding {
    padding-left: 4em;
}

.quad-right-padding {
    padding-right: 4em;
}

.bottom-padding {
    padding-bottom: 1em;
}

.top-padding {
    padding-top: 1em;
}

.left-padding {
    padding-left: 1em;
}

.right-padding {
    padding-right: 1em;
}

.slight-bottom-padding {
    padding-bottom: 0.5em;
}

.slight-top-padding {
    padding-top: 0.5em;
}

.slight-left-padding {
    padding-left: 0.5em;
}

.slight-right-padding {
    padding-right: 0.5em;
}

.add-alittle-space-around {
    padding: 0.5em;
}

.add-some-space {
    padding: 2.5em 0;
}

.add-padding {
    padding: 0 2em 3em;
}

.double-padding {
    padding: 3em 4em;
}

.single-padding {
    padding: 2em 3em;
}

.slight-padding {
    padding: 1.5em;
}

.no-left-margin {
    margin-left: 0;
}

.no-right-margin {
    margin-right: 0;
}

.no-top-margin {
    margin-top: 0;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.no-overflow {
    overflow: hidden;
}

.overflowing {
    overflow: scroll;
}

.content-center,
.center-content {
    margin: 0 auto;
}

.background-cover {
    background-size: cover;
}

.background-center {
    background-position: center center;
}

.background-fixed {
    background-attachment: scroll;
}

.background-norepeat {
    background-repeat: no-repeat;
}

.absolute-center,
.abs-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.abs-x-center {
    left: 50%;
    transform: translateX(-50%);
}

.abs-y-center {
    top: 50%;
    transform: translateY(-50%);
}

.ec-box-shadow {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.no-list-style {
    list-style-type: none;
}

.no-border {
    border: 0;
}

.bordered {
    border: 1px solid;
}

.border-top {
    border-top: 1px solid;
}

.border-bottom {
    border-bottom: 1px solid;
}

.ec-background-style {
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (min-width: 1100px) {
    .background-fixed {
        background-attachment: fixed;
    }
}

/*---- Font Styles ----*/
a {
    text-decoration: none;
}

.icon-xxxl {
    font-size: 56px;
}

h1 {
    font-size: 40px;
}

h2,
.icon-xxl {
    font-size: 32px;
}

h3,
.icon-xl {
    font-size: 24px;
}

h4,
.icon-lg {
    font-size: 20px;
}

h5,
.icon-md {
    font-size: 18px;
}

p,
.icon-s {
    font-size: 16px;
}

input,
textarea,
.ec-link,
.ec-comment {
    font-size: 14px;
}

small,
.icon-xs {
    font-size: 12px;
}

.ec-vsmall,
.icon-xxs {
    font-size: 8px;
}

.ec-primary-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
}

.ec-primary-font--regular {
    font-weight: 400;
}

.ec-primary-font--light {
    font-weight: 300;
}

.ec-secondary-font {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.ec-secondary-font--light {
    font-weight: 500;
}

.ec-link-spacing {
    letter-spacing: 1.5px;
}

.ec-link-margin {
    margin: 0 1.5em;
}

.ec-line-height {
    line-height: 1.3;
}

.ec-col {
    padding: 0.75em;
}

@media screen and (min-width: 960px) {
    .ec-line-height {
        line-height: 1.8;
    }
}

/*---- Color Styles ----*/
.ec-text-black {
    color: #000;
}

.ec-text-dark {
    color: #18181c;
}

input:disabled,
button[disabled],
button:disabled,
button.disabled,
.ec-text-grey,
.disable::before {
    color: rgba(24, 24, 24, 0.5);
}

input:disabled,
button[disabled],
button:disabled,
button.disabled {
    pointer-events: none;
}

.ec-text-white {
    color: #fff;
}

.ec-text-orange,
.ec-hover-orange:hover {
    color: #ff671f;
}

.ec-text-green {
    color: #54cb00;
}

input:disabled,
button[disabled],
button:disabled,
button.disabled,
.ec-no-background {
    background-color: transparent;
}

.ec-background-black {
    background-color: #000;
}

.ec-background-dark {
    background-color: #18181c;
}

.ec-background-darkgrey {
    background-color: #272727;
}

.ec-background-lightgrey {
    background-color: #f7f8f9;
}

.ec-background-grey {
    background-color: #efefef;
}

.ec-background-darkpill {
    background-color: rgba(255, 255, 255, 0.1);
}

.ec-background-white {
    background-color: #fff;
}

.ec-background-orange {
    background-color: #ff671f;
}

.ec-border-gone {
    border-color: transparent;
}

.ec-border-dark {
    border-color: #18181c;
}

.ec-border-lightgrey {
    border-color: #e0e0e0;
}

.ec-border-grey {
    border-color: rgba(24, 24, 24, 0.5);
}

.ec-border-orange {
    border-color: #ff671f;
}

.overlay {
    background-color: rgba(24, 24, 24, 0.75);
    z-index: 0;
}

.fa-facebook-f {
    color: #5c81bc;
}

.fa-google {
    color: #eb4e48;
}

@media screen and (min-width: 767px) {
    .ec-button {
        width: auto;
    }

    .half-width {
        width: 50%;
    }
}

/* --------------
Header Styles
----------------*/
.ec-header {
    height: 64px;
    padding: 0 1em;
}

.logoContainer img {
    max-width: 160px;
}

.ec-header-link {
    margin-right: 1em;
}

/* --------------
Main Content Styles
----------------*/
.main-content {
    max-width: 1200px;
    padding: 5em 0 1em;
}

.main-content-large {
    max-width: 1200px;
}

.main-content-large--padding {
    padding: 5em 0 1em;
}

.page-header {
    height: 85vh;
    max-height: 100%;
}

.page-header-short {
    height: 55vh;
    max-height: 100%;
}

.video-container {
    height: 0;
    padding-bottom: 56.25%;
}

/* ---- Button Styles ---- */
.ec-button {
    margin: 2em 0 0;
    padding: 1.25em;
    transition: 0.2s opacity ease;
}

.ec-pill {
    padding: 1em 2em;
}

.ec-text-button {
    margin: 0 !important;
    padding: 0.25em;
}

.ec-button:hover {
    opacity: 0.9;
}

/* ---- Card Styles ---- */
.card.noBottomPadding {
    padding-bottom: 0;
}

.ecCard {
    padding: 2em;
}

.mainCardWithBug {
    padding: 3.5em 2.5em 2.5em;
}

.ec-bug {
    border-radius: 50px;
    max-width: 70px;
    padding: 0.3em 0;
    right: 1.5em;
    top: 1.5em;
}

.ecCard-icon {
    font-size: 92px;
}

.ecCard-smallPadding {
    padding: 1.5em 1em;
}

@media only screen and (max-width: 991px) {
    .mob-rel-pos {
        position: relative !important;
    }

    .mob-abs-pos {
        position: absolute !important;
    }

    .mob-static-pos {
        position: static !important;
    }

    .mob-fix-pos {
        position: fixed !important;
    }

    .mob-flex {
        display: flex !important;
    }

    .mob-flex-wrap {
        flex-wrap: wrap !important;
    }

    .mob-flex-column {
        flex-direction: column !important;
    }

    .mob-flex-align-center {
        align-items: center !important;
    }

    .mob-flex-align-start {
        align-items: flex-start !important;
    }

    .mob-flex-align-end {
        align-items: flex-end !important;
    }

    .mob-flex-justify-center {
        justify-content: center !important;
    }

    .mob-flex-justify-start {
        justify-content: flex-start !important;
    }

    .mob-flex-justify-end {
        justify-content: flex-end !important;
    }

    .mob-flex-justify-spacearound,
    .mob-flex-justify-around {
        justify-content: space-around !important;
    }

    .mob-flex-justify-spacebetween,
    .mob-flex-justify-between {
        justify-content: space-between !important;
    }

    .mob-flex-justify-even {
        justify-content: space-evenly !important;
    }

    .mob-inline-block {
        display: inline-block !important;
    }

    .mob-text-left {
        text-align: left !important;
    }

    .mob-text-center {
        text-align: center !important;
    }

    .mob-text-right {
        text-align: right !important;
    }

    .mob-first {
        order: 1;
    }

    .mob-second {
        order: 2;
    }

    .mob-third {
        order: 3;
    }

    .mob-fourth {
        order: 4;
    }

    .flex-three-big-column,
    .flex-three-sm-column {
        flex-basis: 100%;
    }

    .vh-100 {
        height: 100vh !important;
    }

    .flex-big-column {
        &.mob-reverse {
            flex-basis: 35% !important;
        }
    }

    .flex-sm-column {
        &.mob-reverse {
            flex-basis: 65% !important;
        }
    }

    .mob-no-overflow {
        overflow: hidden;

        &-y {
            overflow-y: hidden;
        }

        &-x {
            overflow-x: hidden;
        }
    }
}

/* --------------
Form Styles
----------------*/
input,
textarea,
select {
    font-size: 15px;
    padding: 1em 3em;
}

input::before,
input::after {
    border-radius: 0;
}

.input-wrapper::before {
    font-family: "simple-line-icons";
    font-size: 20px;
    left: 14px;
    position: absolute;
    top: 14px;
}

#form-phone::before {
    content: "\e010";
}

#form-email::before {
    content: "\e086";
}

#form-barcode::before {
    content: "\e005";
}

/* --- Errors --- */
.ec-error-background,
.ec-error input,
.ec-error-notnull input {
    background-color: rgb(255, 242, 246);
}

.ec-error-text,
.ec-error small,
.ec-error-notnull small {
    color: #ff0048;
    font-size: 12px;
}

/* --- EC Fonts --- */
@font-face {
    font-display: swap;
    font-family: "elevationchurch";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/elevationchurch.eot") format("embedded-opentype"),
        url("../fonts/elevationchurch.ttf") format("truetype"),
        url("../fonts/elevationchurch.woff") format("woff"),
        url("../fonts/elevationchurch.svg") format("svg");
}

[class^="ecicon-"],
[class*=" ecicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "elevationchurch" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;

    /* Better Font Rendering =========== */
}

.ecicon-egroups::before {
    content: "\e900";
}

.ecicon-elevationchurch::before {
    content: "\e901";
}

.ecicon-eteams::before {
    content: "\e902";
}

.ecicon-giving::before {
    content: "\e903";
}

.ecicon-myelevation-OLD::before {
    content: "\e904";
}

.ecicon-myelevation::before {
    content: "\e905";
}

.ecicon-online::before {
    content: "\e926";
}

.ecicon-bible-active::before {
    content: "\e906";
}

.ecicon-bible::before {
    content: "\e907";
}

.ecicon-chat-active::before {
    content: "\e908";
}

.ecicon-chat::before {
    content: "\e909";
}

.ecicon-check-active::before {
    content: "\e90a";
}

.ecicon-check::before {
    content: "\e90b";
}

.ecicon-close-active::before {
    content: "\e90c";
}

.ecicon-close::before {
    content: "\e90d";
}

.ecicon-comment-active::before {
    content: "\e90e";
}

.ecicon-comment::before {
    content: "\e90f";
}

.ecicon-desktop-active::before {
    content: "\e910";
}

.ecicon-desktop::before {
    content: "\e911";
}

.ecicon-form-active::before {
    content: "\e912";
}

.ecicon-form::before {
    content: "\e913";
}

.ecicon-gear-active::before {
    content: "\e914";
}

.ecicon-gear::before {
    content: "\e915";
}

.ecicon-heart-active::before {
    color: #fc5151;
    content: "\e916";
}

.ecicon-heart::before {
    content: "\e917";
}

.ecicon-new-user-active::before {
    content: "\e918";
}

.ecicon-new-user::before {
    content: "\e919";
}

.ecicon-notes-active::before {
    content: "\e91a";
}

.ecicon-notes::before {
    content: "\e91b";
}

.ecicon-phone-active::before {
    content: "\e91c";
}

.ecicon-phone::before {
    content: "\e91d";
}

.ecicon-schedule-active::before {
    content: "\e91e";
}

.ecicon-schedule::before {
    content: "\e91f";
}

.ecicon-send-active::before {
    content: "\e920";
}

.ecicon-send::before {
    content: "\e921";
}

.ecicon-user-active::before {
    content: "\e922";
}

.ecicon-user::before {
    content: "\e923";
}

.ecicon-users-active::before {
    content: "\e924";
}

.ecicon-users::before {
    content: "\e925";
}

.ecicon-egroups-active::before {
    content: "\e927";
}

.ec-inherit-line-height {
    line-height: inherit;
}
