.egroupleader,
.egroup-lead,
.baptism {
    &-campus {
        &-select {
            padding: 0;
        }

        &-select::after {
            color: #18181c;
            content: "\f078";
            font-family: $icons-font-family;
            font-size: 20px;
            font-weight: 100;
            opacity: 0.35;
            pointer-events: none;
            position: absolute;
            right: 15px;
            top: 12px;
        }

        &-select > select {
            appearance: none;
            background-color: #f4f5f7;
            opacity: 0.5;
            outline: none;
            padding-bottom: 1.4ex !important;
            padding-left: 8px !important;
            padding-top: 1.4ex !important;
        }

        input[type="radio"] {
            display: none;
        }

        .not-selected {
            border-radius: 8px;
        }

        input[type="radio"] {
            display: none;
        }

        input[type="radio"]:checked ~ label::after {
            right: 0.8em !important;
        }

        .ec-radio {
            background-color: transparent;

            label {
                margin: 0;
                position: initial;
            }
        }
    }

    div#{&-age-select} input[type="radio"]:checked ~ label::after {
        content: "";
    }

    &-age-select > label {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    &-selected#{&-age-selected-adult} {
        border-radius: 6px 0 0 6px !important;
    }

    &-selected#{&-age-selected-youth} {
        border-radius: 0 6px 6px 0 !important;
    }

    &-selected {
        background-color: #1da1f2 !important;
    }

    &-event-type-field-wrapper {
        border: 2px solid #1da1f2;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        height: 40px;

        input[type="radio"]:checked ~ span {
            background-color: #1da1f2 !important;
            color: #fff;
            font-family: $secondary-font-family;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.25px;
        }

        div {
            background-color: #fff;
            padding: 0;
            text-align: center;
        }
    }

    &-date-select {
        background-color: #f4f5f7;
        padding: 0;

        & > label {
            display: block;
            margin: 0;
            padding: 13px;
        }
    }
}

.egroupleader {
    button {
        background-color: #ff671f;
    }
}
