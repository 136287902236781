.egroups {
    background-color: #fff;
    @include bp-sm-max {
        .banner {
            min-height: 57px;
            padding-bottom: 10px;
            padding-top: 10px;
            z-index: 9999;

            .brand.site-logo {
                left: 50%;
                position: absolute;
                transform: translateX(-50%);

                .full-color-logo {
                    content: url("https://dv9pmwasxvawj.cloudfront.net/images/connection_center/eGroups/eGroups-Logo.svg");
                    max-width: 80px;
                }
            }

            #header-right {
                padding: 0;

                .weglot-container .wg-drop.country-selector {
                    margin-right: -10px;

                    ul {
                        width: auto;
                    }

                    .wg-li.flag-3.en a::before {
                        display: none !important;
                    }

                    .wgcurrent a::first-letter {
                        text-transform: uppercase;
                    }

                    .wgcurrent a {
                        padding-right: 23px;
                        text-transform: lowercase;
                    }

                    .wgcurrent::after {
                        font-size: 18px;
                        font-weight: 300;
                        top: 10px;
                    }
                }
            }
        }

        &.egroup-map {
            .banner {
                border-bottom: 1px solid rgba(24, 24, 24, 0.08);
                box-shadow: unset;
            }

            .egroups-map {
                .egroup-accordion {
                    .accordion__panel {
                        @include bp-sm-max {
                            &.open {
                                transform: translateY(5vh);

                                &.small {
                                    transform: translateY(25vh);
                                }
                            }
                        }
                    }
                }
            }
        }

        #menu-wrapper {
            left: 0;
            right: initial;
            z-index: 10000;

            .hamburger {
                margin-left: 25px;
                margin-right: 0;
                margin-top: 25px;

                & > div {
                    height: 17px !important;
                    width: 26px !important;
                }
            }
        }
    }

    .button:not(.ec-no-background) {
        background-color: $egroups-color;
    }

    /*
  ** eGroups Home styles
  */

    .egroups-home {
        &.no-scroll {
            height: calc(100vh - #{$menu-height-mobile});

            @media screen and (min-width: 992px) {
                height: calc(100vh - #{$menu-height});
            }

            &.minus-hide-egroups-link {
                height: calc(100vh - #{$menu-height-mobile} - 48px);

                @media screen and (min-width: 992px) {
                    height: calc(100vh - #{$menu-height} - 48px);
                }
            }
        }
    }

    .egroups-home,
    .egroups-map {
        flex-wrap: wrap;

        @include bp-md-min {
            flex-wrap: nowrap;
        }

        @include bp-sm-max {
            padding-left: 0;
            padding-right: 0;

            .mobile-horizontal-rule {
                top: 280px;
            }
        }

        .egroup-map {
            min-height: 10.5rem;
        }

        .line-with-text-in-the-middle {
            border-bottom: 1px solid rgba(24, 24, 24, 0.15);
            height: 10px;
            text-align: center;
            width: 100%;
        }

        .text-in-the-middle {
            background-color: $gray-form-elements;
            font-size: 16px;
            padding: 0 10px;
        }

        .egroup-map-image {
            width: 100%;
        }

        .egroup-map-wrapper .egroup-map-text::after {
            color: #fff;
            content: "\f054";
            font-family: $icons-font-family;
            font-size: 32px;
            font-weight: 100;
            position: absolute;
            right: 10px;
        }

        .egroup-accordion {
            .accordion__button {
                cursor: pointer;
                outline: 0;

                @include bp-sm-max {
                    margin: 0 auto;
                    max-width: max-content;
                }
            }

            .accordion__panel {
                overflow: hidden;

                @include bp-sm-max {
                    max-height: 0;
                    opacity: 0;
                    transform: translateY(100vh);
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                    &.open {
                        max-height: 1000px;
                        opacity: 1;
                        transform: translateY(-5vh);

                        &.small {
                            transform: translateY(15vh);
                        }
                    }
                }
            }
        }

        .filters {
            height: calc(100vh - #{$menu-height});
            width: 100%;

            @include bp-md-min {
                height: calc(100vh - #{$menu-height});
                overflow-y: auto;
                width: 30%;

                &.extra-high {
                    height: 100vh;
                }
            }

            &.no-overflow {
                height: 100%;
                overflow-y: hidden;
            }

            .accordion-wrapper {
                @include bp-md-min {
                    display: block;
                    top: 15px;
                }

                .accordion__item {
                    @include bp-sm-max {
                        align-items: center;
                        display: flex;
                    }
                }

                .accordion__item,
                .accordion__item .demographic-filter-section-border {
                    border-bottom: 1px solid rgba(24, 24, 24, 0.08);
                    margin-bottom: 10px;
                    padding-bottom: 10px;

                    @include bp-sm-max {
                        border: 1px solid rgba(24, 24, 24, 0.08);
                        border-radius: 50px;
                        height: 45px;
                        margin: 0 8px 0 0;
                        padding: 0 10px;
                    }

                    &.m-no-border {
                        @include bp-sm-max {
                            border: 0;
                        }
                    }

                    &.mobile-redirect-to-other-view {
                        border-left: 1px solid rgba(24, 24, 24, 0.08);
                        border-radius: 0;
                    }
                }

                .accordion__panel {
                    top: 46%;
                }
            }

            .switch-filter-wrapper {
                border-bottom: 1px solid $main-gray;
                padding-bottom: 7px;

                .switch-filter {
                    height: 27px;

                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 45px;
                    }

                    /* Hide default HTML checkbox */
                    .switch input {
                        height: 0;
                        opacity: 0;
                        width: 0;
                    }

                    /* The slider */
                    .slider {
                        background-color: #ccc;
                        bottom: 0;
                        cursor: pointer;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        transition: 0.4s;
                        transition: 0.4s;
                    }

                    .slider::before {
                        background-color: #fff;
                        bottom: 3px;
                        content: "";
                        height: 21px;
                        left: 4px;
                        position: absolute;
                        transition: 0.4s;
                        transition: 0.4s;
                        width: 21px;
                    }

                    input:checked + .slider {
                        background-color: $success-green;
                    }

                    input:focus + .slider {
                        box-shadow: 0 0 1px $success-green;
                    }

                    input:checked + .slider::before {
                        transform: translateX(17px);
                        transform: translateX(17px);
                        transform: translateX(17px);
                    }

                    /* Rounded sliders */
                    .slider.round {
                        border-radius: 50px;
                    }

                    .slider.round::before {
                        border-radius: 50%;
                    }
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .filters {
                height: auto;
            }
        }

        .content {
            padding: 0;

            @include bp-md-min {
                border-left: 1px solid $main-gray;
                height: 100%;
                padding: 50px 100px;
                width: 70%;
            }

            &.map-wrapper {
                padding: 0;
            }

            .meeting-type {
                background: #f4f5f7;
                border-radius: 40px;
                margin-top: -2px;
            }
        }

        .community-option {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 65px;
            justify-content: center;

            .text-content {
                padding: 0;
            }

            .icon-div {
                min-width: 40px;
            }

            .card-image {
                display: none;
            }

            h3 {
                color: #fff;
                font-size: 16px;
            }
        }

        .campus-community {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .online-community {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .selected-filter-circle {
        background-color: $success-green;
        border-radius: 100%;
        height: 5px;
        width: 5px;
    }

    .rotate {
        transition: all 300ms ease-out;

        &.down {
            transform: rotate(180deg);
        }
    }

    .filter-clear {
        color: rgba(24, 24, 24, 0.25);
    }

    .filter-clear-btn {
        color: #f00;
        cursor: pointer;
    }

    .list-filter {
        &:hover {
            background-color: $offWhite;

            .list-filter-icon {
                display: block;
            }
        }

        .list-filter-icon {
            color: $dark;
            display: none;
        }

        &.active {
            background-color: rgba(0, 122, 255, 0.04);
            color: $focusBlue;

            .list-filter-icon {
                color: $white;
                display: block;
            }
        }
    }

    .filters-pill {
        background-color: $gray-form-elements;
        border-radius: 1000px;
        color: $dark-gray;
        height: 34px;
        letter-spacing: -0.25px;
        margin-bottom: 5px;
        margin-right: 9px;
        margin-top: 5px;
        outline: 0;
        padding: 2px 14px;

        &.selected {
            background-color: rgba(0, 122, 255, 0.04);
            border: 2px solid rgba(0, 122, 255, 0.3);
            color: $focusBlue;
        }

        &:hover {
            background-color: $main-gray;
        }

        &.disabled {
            background-color: rgba(0, 0, 0, 0.02);
            color: #aeaeb2;

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
            }
        }

        &.white {
            background-color: $white;

            &:hover {
                border: 1px solid #000;
            }
        }

        &.active {
            background-color: $dark-gray;
            color: $white;
        }
    }

    /*
  ** eGroups Home, Type and List Dropdown style
  */

    .select-box {
        margin: 50px 1%;
        position: relative;
    }

    .select-box::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        height: 34px;
        opacity: 0.4;
        padding: 15px 0 0 8px;
        pointer-events: none;
        position: absolute;
        right: 11px;
        top: 0;
    }

    /* IE11 hide native button */
    select::-ms-expand {
        display: none;
    }

    .select-box select {
        appearance: none;
        appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: none;
        border: 1px solid $gray-form-elements;
        color: $dark-gray;

        display: block;
        font-family: $secondary-font-family;
        font-size: 16px;
        height: 50px;
        line-height: 1.75;
        margin: 5px 0;
        max-width: 100%;
        outline: none;
        padding: 0 24px;
        width: 100%;
        -ms-word-break: normal;
        word-break: normal;
    }

    .help-text {
        color: $egroups-color;
    }

    /*
  ** eGroups Type styles
  */

    .egroups-type {
        .campus-loading {
            max-width: 100%;
            width: 300px;
        }

        .egroup-type-wrapper {
            width: 25%;
        }

        .egroups-type h1 {
            font-size: 24px;
        }

        .egroup-type-card {
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
            height: 110px;
            max-width: 90%;
        }

        .egroups-types {
            display: flex;
            flex-wrap: wrap;
        }

        .egroups-type-icon {
            font-size: 40px;
        }

        .egroups-type-text {
            font-size: 16px;
            text-align: center;
        }

        .egroup-type {
            &.loaded {
                background: linear-gradient(
                    134.72deg,
                    #feb984 0%,
                    #ff671f 100%
                );

                &.egroup-type-women {
                    background: linear-gradient(
                        135deg,
                        #de3333 0%,
                        #ce2b49 20%,
                        #bc255e 40%,
                        #a91f72 60%,
                        #931b85 80%,
                        #781999 100%
                    );
                }

                &.egroup-type-men {
                    background: linear-gradient(
                        147.6deg,
                        #50d8db 0%,
                        #5bbed6 20%,
                        #61a4d1 40%,
                        #628acb 60%,
                        #6070c5 80%,
                        #5b56bf 100%
                    );
                }

                &.egroup-type-singles {
                    background: linear-gradient(
                        135deg,
                        #e4d28b 0%,
                        #e6b090 20%,
                        #e98f95 40%,
                        #eb6d99 60%,
                        #ee4c9e 80%,
                        #f02aa3 100%
                    );
                }

                &.egroup-type-married-couples {
                    background: linear-gradient(
                        135deg,
                        #9ee8b9 0%,
                        #72c3a9 20%,
                        #4e9f96 40%,
                        #347b7e 60%,
                        #225863 80%,
                        #163844 100%
                    );
                }

                &.egroup-type-everyone-welcome {
                    background: linear-gradient(
                        135deg,
                        #ffadad 0%,
                        #dd8bb1 20%,
                        #ba6ab4 40%,
                        #9449b6 60%,
                        #6728b7 80%,
                        #1700b8 100%
                    );
                }

                &.egroup-type-college {
                    background: linear-gradient(
                        135deg,
                        #ffce3a 0%,
                        #fbb829 20%,
                        #f5a21b 40%,
                        #ee8c10 60%,
                        #e7750a 80%,
                        #de5d0a 100%
                    );
                }

                &.egroup-type-high-school-girls {
                    background: linear-gradient(
                        135deg,
                        #71ff76 0%,
                        #84d781 20%,
                        #8cb08a 40%,
                        #8c8990 60%,
                        #876194 80%,
                        #7c3397 100%
                    );
                }

                &.egroup-type-high-school-guys {
                    background: linear-gradient(
                        135deg,
                        #ffc27c 0%,
                        #e2a587 20%,
                        #c38990 40%,
                        #a46e98 60%,
                        #81539e 80%,
                        #563ba4 100%
                    );
                }

                &.egroup-type-middle-school-girls {
                    background: linear-gradient(
                        135deg,
                        #f8bf5b 0%,
                        #f6a662 20%,
                        #f28c68 40%,
                        #ee706d 60%,
                        #e85071 80%,
                        #e22074 100%
                    );
                }

                &.egroup-type-middle-school-guys {
                    background: linear-gradient(
                        135deg,
                        #a4ff8b 0%,
                        #91de92 20%,
                        #7cbf98 40%,
                        #64a09c 60%,
                        #46839e 80%,
                        #0e67a0 100%
                    );
                }

                &.egroup-type-next-level {
                    background: linear-gradient(
                        134.72deg,
                        #feb984 0%,
                        #ff5542 100%
                    );
                }

                &.egroup-type-the-crew {
                    background: linear-gradient(
                        134.72deg,
                        #7ae4ff 0%,
                        #fc2f5d 100%
                    );
                }
            }
        }

        .egroup-type-high-school-girls .image-icon,
        .egroup-type-high-school-guys .image-icon,
        .egroup-type-high-school-coed .image-icon,
        .egroup-type-middle-school-girls .image-icon,
        .egroup-type-middle-school-guys .image-icon,
        .egroup-type-youth-coed .image-icon {
            background: url("../images/eGroups/YTH-Logo.png");
            background-size: contain;
            display: block;
            height: 35px;
            margin-bottom: 10px;
            width: 35px;
        }

        .egroup-type-next-level .image-icon {
            background: url("../images/eGroups/Next-Level-Logo.png");
            background-size: contain;
            display: block;
            height: 64px;
            width: 100px;
        }

        .egroup-type-the-crew .image-icon {
            background: url("../images/eGroups/The-Crew-Logo.png");
            background-size: contain;
            display: block;
            height: 23px;
            width: 113px;
        }

        .egroup-type-next-level .egroups-type-text,
        .egroup-type-the-crew .egroups-type-text {
            display: none;
        }

        @media (max-width: 500px) {
            .egroup-type-wrapper {
                width: 50%;
            }

            .egroup-type {
                padding: 0;
            }
        }
    }

    /*
  ** eGroup Type Dropdown
  */

    .egroups-type .select-box select {
        background-color: transparent;
        width: 200px;
    }

    .egroups-type .select-box {
        margin: 0 1%;
    }

    .egroups-type .select:focus {
        outline: none;
    }

    .egroups-type .select-box select {
        opacity: 1;
    }

    .egroup-type-select {
        color: $dark-gray;
    }

    .egroups-type .select-box::after {
        opacity: 1;
    }

    /*
  ** eGroup List Filter
  */

    .egroups-filter {
        background-color: #fff;
        border: 0;
        font-size: 16px;
        height: 48px;
    }

    .egroup-list {
        border-bottom: 1px solid rgba(24, 24, 24, 0.15);
        height: 100px;
    }

    .egroups-list-wrapper p {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
    }

    .egroup-list-result::after {
        color: $dark-gray;
        content: "\f054";
        font-family: $icons-font-family;
        font-size: 24px;
        font-weight: 100;
        position: absolute;
        right: 10px;
    }

    .egroup-list-result {
        border-bottom: 1px solid rgba(24, 24, 24, 0.15);
        height: 100px;
    }

    .campus-loading-indicator {
        height: 102.87px;
    }

    @media (max-width: 320px) {
        .egroup-list-result::after {
            font-size: 16px;
        }
    }

    /*
  ** eGroup List
  */
    .egroups-lists {
        .profile-image {
            margin-left: 1.4ex;
            position: relative;
            width: 100px;
        }

        .avatar {
            border-radius: 50%;
            height: 35px;
            vertical-align: middle;
            width: 35px;
        }

        .avatar2 {
            left: 30px;
            position: absolute;
            top: 10px;
        }

        .avatar-solo {
            border-radius: 50%;
            height: 70px;
            vertical-align: middle;
            width: 70px;
        }

        @media (max-width: 500px) {
            .profile-image {
                margin-left: 0;
                position: relative;
                width: 90px;
            }
        }

        @media (max-width: 320px) {
            .profile-image {
                margin-left: 0;
                position: relative;
                width: 70px;
            }

            .avatar-solo {
                border-radius: 50%;
                height: 65px;
                vertical-align: middle;
                width: 65px;
            }
        }
    }

    /*
  ** eGroup Singles
  */
    .egroups-single {
        .egroup-leader {
            top: 75px;
            transform: translate(0, 20px);

            .avatar {
                border: 5px solid #fff;
            }

            @media (max-width: 991px) {
                top: 0;
                transform: translate(10px, 50px);

                &.egroup-leader-expanded {
                    transform: translate(0, 20px);
                }

                .avatar {
                    top: 0;
                }

                .avatar2 {
                    left: 65px;
                }
            }
        }

        .egroup-view-limited-details {
            margin-left: 40%;
            position: relative;

            .group-details {
                position: absolute;
                right: 0;
            }

            .group-details-btn {
                cursor: pointer;
            }
        }

        .egroup-leader-expanded {
            .avatar {
                top: 0;
            }
        }

        .egroup-leader-image-expanded {
            top: -90px;
        }

        .egroup-leader-image-not-expanded {
            top: -185px;
        }

        .join-button {
            background-color: $egroups-color;
            color: #fff;
        }

        .share-button {
            background-color: $gray-form-elements;
        }

        .egroup-info {
            align-items: center;
            padding-top: 40px;

            h1 {
                font-size: 24px;
                margin-bottom: 0;
            }

            p {
                font-size: 16px;
                opacity: 0.5;
            }
        }

        .egroup-leader-info {
            width: 60%;
        }

        @media (max-width: 991px) {
            .egroup-info {
                align-items: start;
                flex-direction: column;
                margin-bottom: 1.5em;
            }

            .egroup-leader-info {
                width: 100%;
            }

            .egroup-view-limited-details {
                margin-left: 0;
            }

            .egroup-leader-generic-image-not-expanded {
                top: 0;
            }

            .egroup-leader-image-not-expanded {
                top: -145px;
            }
        }

        .join-btn-text-mobile {
            display: none;
        }

        @media (max-width: 768px) {
            .egroup-view-limited-details {
                margin-left: 0;
                padding-top: 55px;
            }

            .join-btn-text-mobile {
                display: block;
            }

            .join-btn-text,
            .group-details-text {
                display: none;
            }
        }

        @media (max-width: 500px) {
            .egroup-more-info {
                flex-direction: column;
            }

            // .egroup-view-limited-details .share-button,
            .join-btn-text,
            .group-details-text {
                display: none;
            }

            .join-btn-text-mobile {
                display: block;
            }
        }

        .egroup-more-info {
            .egroup-details {
                width: 50%;

                p {
                    font-size: 16px;
                }

                p i {
                    color: $dark-gray;
                    font-size: 24px;
                    opacity: 0.5;
                }
            }

            .egroup-desc {
                width: 50%;

                p {
                    font-size: 16px;
                }

                .truncated-text,
                .original-text {
                    color: $dark-gray;
                    opacity: 0.5;
                }

                .more {
                    display: none;
                }

                .more-button {
                    cursor: pointer;
                }
            }

            @media (max-width: 500px) {
                .egroup-details,
                .egroup-desc {
                    width: 100%;
                }
            }

            @media (max-width: 991px) {
                .egroup-details {
                    margin-bottom: 2em;
                }
            }
        }

        @media (max-width: 500px) {
            .egroup-more-info {
                margin-top: 20px;
            }
        }
    }

    .form {
        .egroup-leader-imgs {
            .medium-img {
                flex-shrink: 0;
                height: 73px;
                width: 73px;
            }
        }
    }

    .iconTypeLoading {
        background: #ececef;
        border-radius: 50%;
        height: 40px;
        width: 40px;
    }

    .egroupLeaderLoading {
        height: 100px;
        width: 100px;
    }

    .commentLoading {
        background: #ececef;
        height: 10px;
        margin-top: 20px;
    }

    .curriculum {
        .egroup-curriculum {
            .curriculum-image {
                img {
                    width: 200px;
                }
            }
        }

        .group-studies-image-wrapper {
            img {
                max-height: 70px;
                object-fit: contain;
                width: 44px;
            }
        }
    }
}

/*
** eGroup Modal
*/

.filter-egroups-modal,
.share-egroups-modal {
    background-color: #fff;
    padding: 20px;
    position: absolute;
    top: 287px;

    .modal-select-box {
        position: relative;
    }

    .modal-select-box::after {
        color: $dark-gray;
        content: "\f078";
        font-family: $icons-font-family;
        height: 34px;
        opacity: 0.4;
        padding: 15px 0 0 8px;
        pointer-events: none;
        position: absolute;
        right: 11px;
        top: 6px;
    }

    /* IE11 hide native button */
    select::-ms-expand {
        display: none;
    }

    .modal-select-box select {
        appearance: none;
        appearance: none;
        appearance: none;
        background-color: $gray-form-elements;
        background-image: none;
        border: 0;
        color: #333;
        color: $dark-gray;

        display: block;
        font-size: 16px;
        height: 50px;
        line-height: 1.75;
        margin: 5px 0;
        max-width: 100%;
        opacity: 0.5;
        padding: 0 24px;
        width: 100%;
        -ms-word-break: normal;
        word-break: normal;
    }

    .modal-close {
        background-color: transparent;
        border: 0;
        right: 20px;

        i {
            color: #181818;
            font-size: 16px;
            opacity: 0.4;
        }
    }
}

.share-egroups-modal {
    .copy-link {
        cursor: pointer;
    }

    input {
        background-color: #f5f6f8;
        border: 0;
        border-radius: 8px;
        height: 16px;
        outline: none;
        padding: 1em;
        width: 100%;
    }

    .list-none {
        list-style: none;
        padding: 0;
    }
}

@media (min-width: 501px) {
    .filter-egroups-modal,
    .share-egroups-modal {
        width: 500px;
    }
}

@media (max-width: 500px) {
    .filter-egroups-modal,
    .share-egroups-modal {
        width: 90%;
    }
}

/*
** React Modal
*/
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(51, 51, 51, 0.2) !important;
}

/**
** Egroup EC Map
*/
.egroups-single {
    .map-wrapper {
        height: calc(40vh - 80px);
        min-height: 400px;
    }

    .egroup-single-marker {
        background-color: $egroups-color;
        border-radius: 50%;
        height: 250px;
        width: 250px;
    }

    .expanded-map {
        height: calc(85vh - 80px);
    }

    .expanded-buttons {
        padding-top: 0;
    }

    .egroup-expand {
        transform: translate(-30px, -65px);

        i {
            cursor: pointer;
        }
    }

    @media (max-width: 991px) {
        .egroup-expand i {
            top: -150px;
        }

        .expanded-map {
            height: calc(90vh - 80px);
        }
    }

    @media (max-width: 420px) {
        .expanded-buttons {
            padding-top: 6.3ex;
        }

        .expanded-map {
            height: calc(80vh - 80px);
        }
    }

    .view-more-groups {
        &:focus {
            outline: 0;
        }
    }
}

.egroup-leader-img-ontop:first-of-type,
.eGroups-list-second-leader:first-of-type {
    left: 0;
}

.egroup-leader-img-ontop:nth-of-type(2) {
    left: -10%;
}

.egroup-leader-img-ontop:nth-of-type(3) {
    left: -15%;
}

.egroup-leader-img-ontop:nth-of-type(4) {
    left: -20%;
}

.egroups-leader-no-image {
    display: inline-block;
    font-family: $secondary-font-family;
    font-weight: bold;
    letter-spacing: -4px;
    text-transform: uppercase;

    &.gradient1 {
        background: rgb(252, 227, 138);
        background: linear-gradient(
            45deg,
            rgba(252, 227, 138, 1) 0%,
            rgba(243, 129, 129, 1) 100%
        );
    }

    &.gradient2 {
        background: rgb(245, 78, 162);
        background: linear-gradient(
            45deg,
            rgba(245, 78, 162, 1) 0%,
            rgba(255, 118, 118, 1) 100%
        );
    }

    &.gradient3 {
        background: rgb(23, 234, 217);
        background: linear-gradient(
            45deg,
            rgba(23, 234, 217, 1) 0%,
            rgba(96, 120, 234, 1) 100%
        );
    }

    &.gradient4 {
        background: rgb(98, 39, 116);
        background: linear-gradient(
            45deg,
            rgba(98, 39, 116, 1) 0%,
            rgba(197, 51, 100, 1) 100%
        );
    }

    &.gradient5 {
        background: rgb(113, 23, 234);
        background: linear-gradient(
            45deg,
            rgba(113, 23, 234, 1) 0%,
            rgba(234, 96, 96, 1) 100%
        );
    }

    &.gradient6 {
        background: rgb(66, 230, 149);
        background: linear-gradient(
            45deg,
            rgba(66, 230, 149, 1) 0%,
            rgba(59, 178, 184, 1) 100%
        );
    }

    &.gradient7 {
        background: rgb(240, 47, 194);
        background: linear-gradient(
            45deg,
            rgba(240, 47, 194, 1) 0%,
            rgba(96, 148, 234, 1) 100%
        );
    }
}

.egroup-single-container {
    min-height: calc(100vh - #{$menu-height-mobile});

    @media screen and (min-width: 992px) {
        min-height: calc(100vh - #{$menu-height});
    }
}

.eGroups-list-second-leader:nth-of-type(2) {
    bottom: 1em;
    left: 1.5em;
}

#groups-list {
    @include bp-md-min {
        background-color: #fff !important;
        border-radius: 8px;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        flex-basis: 58.33333%;
        margin-left: 24px;
        max-width: 58.33333%;
        padding-bottom: 1.4ex;
        padding-left: 2.8ex;
        padding-right: 2.8ex;
        padding-top: 1.4ex;
    }

    @include bp-sm-max {
        background-color: transparent !important;
        border-radius: 0;
        box-shadow: none;
        box-sizing: initial;
        flex-basis: initial;
        max-width: 100% !important;
        padding: 0 !important;
        width: 100% !important;
    }
}

#groups-list-filter {
    @include bp-md-min {
        box-sizing: border-box;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
}

.slider-swiper-button-prev,
.slider-swiper-button-next {
    top: 40%;
}

.swiper-button-left {
    left: -3vw;
}

.swiper-button-right {
    right: -3vw;
}

.swiper-button-disabled {
    display: none;
}

.egroups-home-swiper-for-you {
    .swiper-container {
        @include bp-sm-max {
            padding: 10px 12px !important;
        }
    }
}

.egroups-home-swiper-collections {
    .swiper-container {
        @include bp-sm-max {
            padding: 10px 12px !important;
        }
    }

    .photo-wrapper.wide {
        padding-bottom: 15vh;

        @include bp-sm-max {
            padding-bottom: 100%;
        }

        & > div {
            background-size: 95%;
        }
    }
}

.collections-wrapper {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(auto-fill, 47%);
    justify-content: space-between;

    @media (min-width: 780px) {
        grid-template-columns: repeat(auto-fill, 32%);
    }
}

.egroups-link {
    background-color: $egroupsBlue;

    &:hover {
        color: $white;
    }
}

.EgroupCampusSelector {
    @media (max-height: 760px) {
        height: 100%;
    }
}
