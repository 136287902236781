@import "./mixins/global";

html,
body {
    font-family: $primary-font-family;
    width: 100vw;

    &:not(.fix-scrolling) {
        overflow: auto;
        position: relative;

        #__next {
            height: 100%;
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    #__next {
        font-family: $primary-font-family;
    }
}

.img-cover {
    object-fit: cover;
}

.light-grey-bg {
    background-color: $offWhite;
}

.fit-content {
    max-width: fit-content;
}

.not-clickable {
    pointer-events: none;
}

.pill-tag {
    background-color: rgba(0, 122, 255, 0.04);
    border: 2px solid rgba(0, 122, 255, 0.3);
    border-radius: 50px;
    border-radius: 1000px;
    color: #007aff;
    height: 32px;
    line-height: 28px;
}

.removeFilter {
    color: #ff0048;
    cursor: default;
    height: 28px;
}

.light-overlay {
    background-color: #000;
    border-radius: 16px;
    opacity: 0.5;
}

.ec-border-radius-16 {
    border-radius: 16px;
}

.whole-page-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(1123px - 333px + ((100% - 1123px) / 2));
    z-index: 10;
}

.w-max-content {
    width: max-content;
}

.no-overflow {
    overflow: hidden;

    &-y {
        overflow-y: hidden !important;
    }

    &-x {
        overflow-x: hidden;
    }
}

.overflow-auto {
    overflow: auto;

    @include bp-sm-max {
        &-mob {
            overflow: auto !important;
        }
    }
}

.overflow-scroll {
    &-x-only {
        overflow-x: scroll;
        overflow-y: visible;
    }

    &-y-only {
        overflow-x: auto;
        overflow-y: visible;
    }
}

.hide-scrollbars {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

#__next {
    font-family: $primary-font-family;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
}

/** Typography */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
i,
span,
input,
button {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1,
.icon-3xl {
    @include font-size(32px);

    @include bp-md-min {
        @include font-size(40px);
    }
}

h2,
.icon-2xl {
    @include font-size(32px);
}

h3 {
    @include font-size(24px);
}

h4,
small,
.btn-txt,
.single-curriculum li {
    @include font-size(16px);
}

.small-link {
    @include font-size(14px);
}

.sm-title {
    @include font-size(13px);

    @include bp-md-min {
        @include font-size(16px);
    }
}

h5 {
    @include font-size(12px);
}

h6 {
    @include font-size(10px);
}

p {
    @include font-size(20px);
}

.smaller {
    @include font-size(10px);

    @include bp-md-min {
        @include font-size(12px);
    }
}

h1,
h2,
h3,
button,
strong,
a,
.sec-font {
    font-family: $secondary-font;
    font-weight: $font-weight-bolder;
}

.sec-font {
    letter-spacing: $secondary-letter-spacing;
}

.sec-font {
    span {
        font-family: $secondary-font !important;
    }
}

h4,
h5,
h6,
p,
span,
.prim-font {
    font-family: $primary-font;

    &.prim-font {
        font-family: $primary-font !important;
    }
}

.prim-font {
    letter-spacing: $primary-letter-spacing;
}

button {
    cursor: pointer;
}

a {
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.bold {
    font-weight: $font-weight-bolder;
}

.mont-bold {
    font-weight: $font-weight-bold;
}

.regular,
.regular p {
    font-weight: $font-weight-regular;
}

.let-sp-reg,
%let-sp-reg {
    letter-spacing: calculaterem(1.75px);
}

.ln-height-reset {
    line-height: 1;
}

.ln-height-slight {
    line-height: 1.4;
}

.sgl-ln-height,
.sgl-ln-height p {
    line-height: 1.5;
}

.dbl-ln-height {
    line-height: 1.7;
}

.parent-route-wrapper {
    position: relative;
}

:focus:not(:focus-visible) {
    outline: none;
}

.inline-flex {
    display: inline-flex;
}

.max-w-400 {
    max-width: 400px;
}

.background-alt-light-blue {
    background-color: #acd7ee;
}

.background-transdark-grey {
    background-color: rgba(24, 24, 28, 0.5);
}

.background-childcare-blue {
    background-color: #1877f2;
}

.text-childcare-blue {
    color: #1877f2;
}

.background-active-blue {
    background-color: $focusBlue !important;
}

.text-active-blue {
    color: $focusBlue !important;
}

.border-active-blue {
    background-color: rgba(0, 122, 255, 0.04);
    border: 1px solid rgba(0, 122, 255, 0.3) !important;
}

.text-egroups-green {
    color: $egroups-color;
}

.hover-text-white {
    &:hover {
        color: #fff;
    }
}

.hover-green {
    &:hover {
        color: $egroups-color;
    }
}

.text-confirm {
    color: #6dd400;
}

.text-underline {
    text-decoration: underline;
}

.xsmall-link {
    font-size: 0.775rem;
}

.xs-icon {
    height: 20px;
    max-width: 100%;
    width: 20px;
}

.sm-icon {
    height: 24px;
    max-width: 100%;
    width: 24px;
}

.lg-icon {
    font-size: 24px;
}

.small-img {
    height: 72px;
    max-width: 100%;
    width: 72px;
}

.xsmall-img {
    height: 45px;
    width: 45px;
}

.lighter {
    font-weight: 300;
}

.small-box {
    min-width: 4.6875rem;
}

.add-bottom-padding--main {
    padding-bottom: 45%;
}

.button.disabled {
    background-color: #f2f4f7 !important;
}

.button.gray {
    background-color: #afafaf !important;
}

.no-background {
    background: transparent;
}

.mobile-max-width-180 {
    @include bp-sm-max {
        max-width: 180px;
    }
}

.mobile-max-width-500 {
    @include bp-sm-max {
        max-width: 500px;
    }
}

.max-width-40-percent {
    max-width: 40%;
}

.mobile-max-width-90-percent {
    @include bp-sm-max {
        max-width: 90%;
    }
}

.fullPercentWidth {
    width: $fullPercent;
}

.ln-height-1-4em {
    line-height: 1.4em;
}

.off-selection {
    color: rgba(24, 24, 28, 0.25);
}

.selected-box {
    @include selected-border();
}

.off-selected-box {
    border: 1px solid rgba(24, 24, 28, 0.1) !important;
}

.one-quarter-line-height {
    line-height: 1.25;
}

.update-data-step {
    .ec-radio {
        background: transparent;
    }
}

.double-border-radius {
    border-radius: 16px;
}

.wrap-text {
    white-space: normal;
}

.ec-border-radius,
.ec-border-radius-sm {
    border-radius: 8px;
}

.ec-light-box-shadow {
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 2%);
}

.ec-box-shadow,
%ec-box-shadow {
    box-shadow: 0 32px 32px -24px rgba(24, 24, 24, 0.25);
}

.ec-light-box-shadow {
    box-shadow: 0 4px 11px -8px rgba(0, 0, 0, 0.5);
}

.cursor-pointer {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap;
}

/** Colors */
.background-white {
    background-color: $white;

    &.btn {
        background-color: $white !important;
    }
}

.background-off-white {
    background-color: $offWhite;

    &.btn {
        background-color: $offWhite !important;
    }
}

.background-trans-white {
    background-color: $translucentwhite;

    &.btn {
        background-color: $translucentwhite !important;
    }
}

.background-light-grey {
    background-color: $lightgrey;

    &.btn {
        background-color: $lightgrey !important;
    }
}

.background-grey {
    background-color: $grey;

    &.btn {
        background-color: $grey !important;
    }
}

.background-dark {
    background-color: $dark;

    &.btn {
        background-color: $dark !important;
    }
}

.background-dark-grey {
    background-color: $darkGrey;

    &.btn {
        background-color: $darkGrey !important;
    }
}

.background-trans-dark-grey {
    background-color: $translucentDarkGrey;

    &.btn {
        background-color: $translucentDarkGrey !important;
    }
}

.background-trans-dark {
    background-color: rgba(0, 0, 0, 0.82);

    &.btn {
        background-color: rgba(0, 0, 0, 0.82) !important;
    }
}

.background-trans-light-grey {
    background-color: $translucentLightGrey;

    &.btn {
        background-color: $translucentLightGrey !important;
    }
}

.background-alt-light-grey {
    background-color: $altLightGrey;

    &.btn {
        background-color: $altLightGrey !important;
    }
}

.background-alt-dark-grey {
    background-color: $altDarkGrey;

    &.btn {
        background-color: $altDarkGrey !important;
    }
}

.background-orange {
    background-color: $ecOrange;

    &.btn {
        background-color: $ecOrange !important;
    }
}

.background-green {
    background-color: $egroups-color;

    &.btn {
        background-color: $egroups-color !important;
    }
}

.background-bright-green {
    background-color: $green;

    &.btn {
        background-color: $green !important;
    }
}

.background-success {
    background-color: $success;

    &.btn {
        background-color: $success !important;
    }
}

.background-red {
    background-color: $red;

    &.btn {
        background-color: $red !important;
    }
}

.background-youtube-red {
    background-color: $youtubeRed;

    &.btn {
        background-color: $youtubeRed !important;
    }
}

.background-light-blue {
    background-color: $lightBlue;

    &.btn {
        background-color: $lightBlue !important;
    }
}

.background-alt-light-blue {
    background-color: $altLightBlue;

    &.btn {
        background-color: $altLightBlue !important;
    }
}

.background-blue {
    background-color: $blue;

    &.btn {
        background-color: $blue !important;
    }
}

.text-success {
    color: $success-green;
}

.text-white {
    color: $white;

    &:hover {
        color: $white !important;
    }
}

.text-off-white {
    color: $offWhite;
}

.text-trans-white {
    color: $translucentwhite;
}

.text-light-grey {
    color: $lightgrey;
}

.text-grey {
    color: $grey;
}

.text-dark,
.single-curriculum li {
    color: $dark;
}

.text-dark-grey {
    color: $darkGrey;
}

.text-trans-dark-grey {
    color: $translucentDarkGrey;
}

.text-trans-light-grey {
    color: $translucentLightGrey;
}

.text-trans-light-grey-alt {
    color: $translucentLightGreyAlt;
}

.text-alt-light-grey {
    color: $altLightGrey;
}

.text-alt-dark-grey {
    color: $altDarkGrey;
}

.text-orange {
    color: $ecOrange;

    &:hover {
        color: $ecOrange !important;
    }
}

.text-bright-green {
    color: $green;
}

.text-red {
    color: $red;
}

.text-youtube-red {
    color: $youtubeRed;
}

.text-light-blue {
    color: $lightBlue;
}

.text-blue {
    color: $blue;
}

.border-white {
    border-color: $white;
}

.border-off-white {
    border-color: $offWhite;
}

.border-trans-white {
    border-color: $translucentwhite;
}

.border-trans-light-grey-alt {
    border-color: $translucentLightGreyAlt;
}

.border-focus-blue {
    border-color: $focusBlue;
}

.border-light-grey,
.border-main-grey {
    border-color: $lightgrey;
}

.border-grey {
    border-color: $grey;
}

.border-dark {
    border-color: $dark;
}

.border-dark-grey {
    border-color: $darkGrey;
}

.border-trans-dark-grey {
    border-color: $translucentDarkGrey;
}

.border-trans-light-grey {
    border-color: $translucentLightGrey;
}

.border-alt-light-grey {
    border-color: $altLightGrey;
}

.border-alt-dark-grey {
    border-color: $altDarkGrey;
}

.border-orange {
    border-color: $ecOrange;
}

.border-green {
    border-color: $egroups-color;
}

.border-bright-green {
    border-color: $green;
}

.border-red {
    border-color: $red;
}

.border-youtube-red {
    border-color: $youtubeRed;
}

.border-light-blue {
    border-color: $lightBlue;
}

.border-blue {
    border-color: $blue;
}

.photo-wrapper {
    background-color: $white;
    height: 0;
    overflow: hidden;

    &.wide {
        padding-bottom: 60%;

        @include bp-sm-max {
            padding-bottom: 56%;
        }
    }

    &.square {
        padding-bottom: 100%;
    }

    &.tall {
        padding-bottom: 26vh;
    }
}

.background-contain {
    background-size: contain;
}

.fade-in {
    animation: fadeIn ease 0.2s;
}

h1,
h2 {
    letter-spacing: $sec-title-letter-spacing;
}

h3,
h4,
h5,
h6 {
    letter-spacing: $sec-sub-title-letter-spacing;
}

h1.prim-font,
h2.prim-font,
h3.prim-font {
    letter-spacing: $primary-sub-title-letter-spacing;
}

.border-trans-dark-grey {
    border-top: solid 1px hsla(0, 0%, 9%, 0.15) !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease 0.2s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.xl-list-p-b {
    padding-bottom: 100vh;
}

.mob-swiper-x-padding {
    padding-left: 5.394px !important;
    padding-right: 5.394px !important;
}

@media (max-width: 500px) {
    .mob-swiper-x-padding {
        padding-left: 17.394px !important;
        padding-right: 17.394px !important;
    }
}

.half-height {
    height: 50%;
    max-height: 100%;
}

.medium-gradient-background {
    background: linear-gradient(180deg, transparent, #000 150%);
}
